import React from 'react'
import Loader from '../Components/Atoms/Loader/Loader'

function WorkInProgress() {
  return (
    <div className='px-12 h-[80vh] flex flex-col items-center text-center justify-center'>
        <h1 className='text-6xl font-bold'>PAGINA IN COSTRUZIONE</h1>
        <Loader/>
    </div>
  )
}

export default WorkInProgress