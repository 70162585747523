import React, { useState } from "react";
import NormalBtn from "../../Atoms/Buttons/NormalBtn";

function Megatoggle3() {
	const [first, setFirst] = useState(false);

	return (
		<div className="md:w-[600px] w-[370px] md:h-44 h-32 p-3 items-center shadow-xl rounded-full flex bg-[url(./Assets/progetti.jpg)] bg-cover border">
			<div className={`rounded-full h-full w-full  relative`}>
				<div
					className={`${
						first ? "opacity-90" : "opacity-0"
					} rounded-full h-full w-full object-cover transition-opacity duration-500 top-0 absolute bg-white `}
				></div>
				<div
					onClick={() => {
						setFirst(!first);
					}}
					className={`top-0 h-full cursor-pointer shadow-lg z-20 aspect-square rounded-full bg-white  transition-all duration-500 border-gray-400 text-center absolute flex flex-col items-center justify-center ${
						first ? "left-0  " : "left-full -translate-x-full"
					}`}
				>
					<div
						className={`px-2 rounded-full  absolute top-1/2 -translate-y-1/2 flex flex-col transition-colors duration-300 items-center justify-center aspect-square ${
							first ? "text-black" : "text-black"
						}`}
					>
						<p className="">PROGETTI</p>
						<svg
							className={`${
								first ? "-rotate-180 text-white" : "rotate-0"
							} transition-all duration-500`}
							width="40px"
							height="40px"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							fill={first ? "#000" : "#000"}
						>
							<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
							<g
								id="SVGRepo_tracerCarrier"
								stroke-linecap="round"
								stroke-linejoin="round"
							></g>
							<g id="SVGRepo_iconCarrier">
								{" "}
								<title></title>{" "}
								<g id="Complete">
									{" "}
									<g id="arrow-left">
										{" "}
										<g>
											{" "}
											<polyline
												data-name="Right"
												fill="none"
												id="Right-2"
												points="7.6 7 2.5 12 7.6 17"
												stroke={first ? "#000" : "#000"}
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
											></polyline>{" "}
											<line
												fill="none"
												stroke={first ? "#000" : "#000"}
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												x1="21.5"
												x2="4.8"
												y1="12"
												y2="12"
											></line>{" "}
										</g>{" "}
									</g>{" "}
								</g>{" "}
							</g>
						</svg>
					</div>
				</div>
				<p
					className={`absolute grid grid-cols-1 place-items-center justify-items-end z-10 rounded-full transition-opacity duration-500 md:right-6 right-0 translate-y-[-50%] top-[50%] md:ml-6 px-5 h-auto md:w-[370px] w-[300px]	${
						first ? " block" : "hidden"
					}`}
				>
					<div className="px-2">
						<NormalBtn
							title="Montagna in campo"
							sfondo="blue"
							href="/circolo/montagna-in-campo"
							flag="false"
						/>
					</div>
					<div className="px-2 place-self-end">
						<NormalBtn
							title="Lib(e)ri in montagna - un'estate in biblioteca"
							sfondo="green"
							href="/circolo/lib(e)ri"
							flag="false"
						/>
					</div>{" "}
				</p>
			</div>
		</div>
	);
}

export default Megatoggle3;
