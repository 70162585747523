import React, { useState } from "react";

function Balme() {
	const [open, setOpen] = useState(false);
	return (
		<div>
			<button
				onClick={() => setOpen(true)}
				data-modal-target="extralarge-modal"
				data-modal-toggle="extralarge-modal"
				className="block  w-[220px] h-[60px] m-12 text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center "
				type="button"
			>
				Meteo Balme
			</button>

			<div
				id="extralarge-modal"
				tabindex="-1"
				className={`${
					open ? "block" : "hidden"
				} top-0 left-1/2 absolute -translate-x-1/2 z-50  w-full p-4 overflow-x-hidden overflow-y-auto bg-gray-600 bg-opacity-40 h-[100vh]`}
			>
				<div className="top-[15vh] left-1/2 absolute -translate-x-1/2 w-full max-w-7xl max-h-full">
					<div className="relative bg-white rounded-lg shadow ">
						<div className="flex items-center justify-between p-5 border-b rounded-t ">
							<h3 className="text-xl font-medium text-gray-900 ">
								Meteo Balme
							</h3>
							<button
								onClick={() => setOpen(false)}
								type="button"
								className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
								data-modal-hide="extralarge-modal"
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd"
									></path>
								</svg>
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="flex flex-col md:flex-row mt-5 md:space-x-12 justify-center items-center">
							<a className="hover:underline cursor-pointer" href="https://www.3bmeteo.com/meteo/balme">Meteo Balme</a>
							<iframe
								src="https://www.3bmeteo.com/moduli_esterni/localita_7_giorni_orario/486/ffffff/3b82f6/5e5e5e/ffffff/it"
								className="w-[382px] h-[497px]"
								frameborder="0"
							></iframe>
							<div>
								<iframe
									src="https://www.3bmeteo.com/moduli_esterni/localita_7_giorni_neve/486/ffffff/3b82f6/5e5e5e/ffffff/it"
									className="w-[382px] h-[312px]"
									frameborder="0"
								></iframe>
								<br />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Balme;
