import React, { useState } from "react";

function Dropdown({ title, link, elements, click, className }) {
    const [open, setOpen] = useState(false);
    const [little, setLittle] = useState(0);

    return (
        <div className={className}>
            <div
                onMouseEnter={() => window.innerWidth > 768 && setOpen(true)}
                onMouseLeave={() => window.innerWidth > 768 && setOpen(false)}
            >
                <button
                    onClick={() => {
                        !click && (window.location.href = link);
                        window.innerWidth < 768 && setOpen(!open);
                    }}
                    onMouseEnter={() =>
                        window.innerWidth > 768 && setOpen(true)
                    }
                    onMouseLeave={() =>
                        window.innerWidth > 768 && setOpen(false)
                    }
                    className={`font-medium rounded-lg  ${
                        !click
                            ? "hover:underline cursor-pointer"
                            : "cursor-default"
                    } text-center inline-flex items-center`}
                    type="button"
                >
                    {title}
                    <svg
                        className="w-4 h-4 ml-2"
                        aria-hidden="true"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                        ></path>
                    </svg>
                </button>

                <div
                    className={`${
                        open ? "block" : "hidden"
                    } z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-52 `}
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                >
                    <ul
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}
                        className="py-2 "
                    >
                        {elements.map((element, i) => {
                            if (element.child.length > 0) {
                                return (
                                    <div
                                        onMouseEnter={() =>
                                            window.innerWidth > 768 &&
                                            document
                                                .getElementById(
                                                    "doubleDropdown" + i
                                                )
                                                .classList.toggle("hidden")
                                        }
                                        onMouseLeave={() =>
                                            window.innerWidth > 768 &&
                                            document
                                                .getElementById(
                                                    "doubleDropdown" + i
                                                )
                                                .classList.toggle("hidden")
                                        }
                                    >
                                        <button
                                            id="doubleDropdownButton"
                                            data-dropdown-toggle="doubleDropdown"
                                            data-dropdown-placement="right-start"
                                            type="button"
                                            className="flex items-center text-left justify-between w-full px-4 py-2 hover:bg-gray-100"
                                            onClick={() => {
                                                document
                                                    .getElementById(
                                                        "doubleDropdown" + i
                                                    )
                                                    .classList.toggle("hidden");
                                                little !== i
                                                    ? setLittle(i)
                                                    : setLittle(0);
                                            }}
                                        >
                                            {element.titolo}
                                            <svg
                                                aria-hidden="true"
                                                className="w-4 h-4"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </button>
                                        <div
                                            onMouseEnter={() =>
                                                window.innerWidth > 768 &&
                                                setOpen(true)
                                            }
                                            onMouseLeave={() =>
                                                window.innerWidth > 768 &&
                                                setOpen(false)
                                            }
                                            id={"doubleDropdown" + i}
                                            className={`z-30 ${
                                                little === i ? "flex" : "hidden"
                                            } flex-col translate-x-full -translate-y-1/3 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-52 `}
                                        >
                                            <ul
                                                className="py-2 text-sm text-black"
                                                aria-labelledby="doubleDropdownButton"
                                            >
                                                {element.child.map(
                                                    (child, j) => {
                                                        return (
                                                            <li>
                                                                <a
                                                                    href={
                                                                        child.link
                                                                    }
                                                                    className="block px-4 py-2 hover:bg-gray-100 text-left"
                                                                >
                                                                    {
                                                                        child.titolo
                                                                    }
                                                                </a>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <li>
                                        <a
                                            href={element.link}
                                            className="block text-black px-4 py-2 hover:bg-gray-100 text-left"
                                        >
                                            {element.titolo}
                                        </a>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Dropdown;
