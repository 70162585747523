import React from "react";

function Chisiamo() {
	return (
		<div className="block w-full">
			<h1 className="text-center text-4xl uppercase font-bold my-2">
				CHI SIAMO
			</h1>
			<div className="px-12 text-justify">
				<p className="mt-4">
					Il Circolo Mondronese, nato come associazione di amici e frequentatori
					abituali della frazione allo scopo di aggregare e organizzare
					manifestazioni nel periodo estivo, si è sviluppato nel tempo fino a
					raggiungere la configurazione negli ultimi anni di Ente del Terzo
					Settore (APS-ETS) iscritto al{" "}
					<a
						className="text-blue-500 hover:underline cursor-pointer"
						href="https://servizi.lavoro.gov.it/runts/it-it/Lista-enti"
					>
						RUNTS
					</a>{" "}
					(Registro Unico Nazionale Terzo Settore).
				</p>
				<p className="mt-4">
					Attualmente aderiscono circa 70 nuclei familiari tramite un
					tesseramento che si rinnova annualmente.
				</p>
				<p className="mt-4">
					Il Circolo opera attraverso un Consiglio Direttivo composto da
					Presidente, Vicepresidente, Segretario tesoriere e un gruppo di
					Consiglieri. Il Consiglio si riunisce periodicamente per definire
					proposte e iniziative da realizzare nella stagione estiva e curare la
					stesura del bilancio preventivo. Elemento fondamentale del Circolo
					Mondronese è l’Assemblea sociale che viene convocata sia all’apertura
					della stagione per la presentazione delle varie iniziative
					programmate, sia in chiusura per verificare l’andamento e accogliere
					osservazioni e suggerimenti utili da parte dei soci.
				</p>
			</div>
		</div>
	);
}

export default Chisiamo;
