import React from "react";

function AreaSportiva() {
	return (
		<div className="my-12 md:px-24 px-6">
			<h1 className="text-center text-4xl md:text-6xl uppercase font-semibold my-4">
				Area sportiva
			</h1>
			<div className="text-justify">
				<p className="mt-7">
					Immersa nello splendido scenario montano si trova un'area sportiva
					polifunzionale che diventa il centro dell'attività fisica e del
					divertimento durante il periodo estivo. Questa versatile area è
					attrezzata per ospitare partite di pallavolo, basket e il tipico
					mondronese gioco del bidone, offrendo spazio sia per il gioco libero
					che per entusiasmanti tornei.
				</p>
				<p className="mt-7">
					L'area sportiva è adiacente ad un campo da calcio, ricavato con cura
					in un prato pianeggiante che si fonde perfettamente con il paesaggio
					naturale circostante. Questa configurazione permette agli appassionati
					di calcio di godere di un campo accogliente, mentre gli altri sportivi
					possono sfidarsi nei loro giochi preferiti sulla pavimentazione
					polifunzionale.
				</p>
				<p className="mt-7">
					Per gli spettatori, sono stati costruiti gradoni in pietra che offrono
					una comoda seduta e una visuale perfetta sui campi da gioco. Questi
					gradoni, oltre a fornire un'atmosfera rustica e integrata con
					l'ambiente montano, creano un'area di ritrovo dove gli amici e le
					famiglie possono assistere alle partite e fare il tifo per i loro
					preferiti.
				</p>
				<p className="mt-7">
					Vicino all'area sportiva, una fontana in legno, con la sua acqua
					fresca e cristallina, offre un ristoro immediato per gli atleti e gli
					spettatori, permettendo di rinfrescarsi e rigenerarsi tra una partita
					e l'altra. Vicino alla fontana una panchina di legno con l’incisione
					“Accomodati e pensa a una persona per te speciale”.
				</p>
				<p className="mt-7">
					Questo angolo di Mondrone non è solo un luogo di competizione e
					attività fisica, ma anche un punto di incontro comunitario, dove lo
					sport e la natura si fondono per creare momenti di gioia e
					condivisione.
				</p>
				
			</div>
			<div className="flex flex-col md:flex-row gap-7 items-center justify-between mt-7">
				<div className="flex flex-col justify-center">
					<img
						className="max-w-full h-auto"
						src={require("../../../Assets/Paese/area sportiva 1 .jpg")}
						alt=""
					/>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="max-w-full h-auto"
						src={require("../../../Assets/Paese/area sportiva 2 .jpg")}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}

export default AreaSportiva;
