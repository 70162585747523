import React from "react";

function GiochiBimbi() {
	return (
		<div className="my-12 md:px-24 px-6">
			<h1 className="text-center text-4xl md:text-6xl uppercase font-semibold my-4">
				Giochi per i bimbi
			</h1>
			<div className="text-justify">
				<p className="mt-7">
					A Mondrone, l'area per gioco bimbi è uno spazio dedicato ai più
					piccoli, immerso nella natura e situato in un prato verde vicino
					all'area sportiva. Circondata da una robusta staccionata in legno che
					garantisce sicurezza e tranquillità ai genitori, l'area è attrezzata
					con una varietà di giochi che assicurano divertimento e avventura per
					i bambini di tutte le età.
				</p>
				<p className="mt-7">
					Un'altalena dolcemente sospesa invita a volare tra le nuvole, mentre
					uno scivolo promette discese emozionanti. Accanto, i giochi a molla,
					colorati e allegri, stimolano la fantasia e l'energia dei più piccoli.
					Altra attrazione è la struttura gioco per arrampicata, dove i bimbi
					possono mettere alla prova le loro abilità motorie arrampicandosi su
					corde e gradini.
				</p>
				<p className="mt-7">
					Completano questo spazio per il divertimento dei bimbi un tavolo di
					legno con annesse panche per la merenda, perfetto per le pause
					rigeneranti dopo tanto gioco. Qui, le famiglie possono sedersi
					insieme, gustare uno spuntino all'aperto e godere della vista
					panoramica delle montagne circostanti. L'area per gioco bimbi di
					Mondrone non è solo un luogo di svago, ma anche un punto di incontro e
					socializzazione, dove i bambini possono creare ricordi indimenticabili
					ai piedi delle montagne.
				</p>
			</div>
			<div className="flex flex-col md:flex-row items-center justify-around gap-7 mt-7">
				<div className="flex flex-col justify-center">
					<img
						className="max-w-full h-auto"

						src={require("../../../Assets/Paese/foto da aggiungere a giochi bimbi.jpg")}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}

export default GiochiBimbi;
