import React, { useEffect, useState } from "react";
import {
	getDownloadURL,
	getStorage,
	ref,
	uploadBytes,
	uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../firebase";
import {
	collection,
	addDoc,
	getDocs,
	deleteDoc,
	doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Document, Page } from "react-pdf";
import IsLogged from "./IsLogged";

function Giornalino() {
	if (!IsLogged()) {
		window.location.href = "/admin";
	}

	const [file, setFile] = useState(null);
	const [Data, setData] = useState([]);
	const [ID, setID] = useState([]);
	const [canc, setCanc] = useState(-1);
	const [PDF, setPDF] = useState("");

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	const upload = (e) => {
		const name = Date.now() + file.name;
		e.preventDefault();
		const storageRef = ref(storage, "giornalino/" + name);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",

			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log("Upload is " + progress + "% done");
				switch (snapshot.state) {
					case "paused":
						console.log("Upload is paused");
						break;
					case "running":
						console.log("Upload is running");
						break;
					default:
						console.log("Upload is unknown");
						break;
				}
			},
			(error) => {
				switch (error.code) {
					case "storage/unauthorized":
						break;
					case "storage/canceled":
						break;

					case "storage/unknown":
						break;
					default:
						console.log("General Error");
						break;
				}
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					console.log("File available at", downloadURL);
					addDoc(collection(db, "giornalino"), {
						url: downloadURL,
						name: file.name,
						date: Date.now(),
					});
					handleLoad();
				});
			}
		);
	};

	const handleLoad = async () => {
		const querySnapshot = await getDocs(collection(db, "giornalino"));
		const docs = querySnapshot.docs.reverse();
		setID(docs.map((doc) => doc.id));
		setData(docs.map((doc) => doc.data()).reverse());
		console.log(docs.map((doc) => doc.data()));
	};

	const elimina = async (i) => {
		await deleteDoc(doc(db, "giornalino", ID[i]));
		handleLoad();
		setCanc(-1);
	};

	useEffect(() => {
		handleLoad();
	}, []);

	return (
		<div className="flex md:flex-row flex-col w-[100vw] ">
			<div className="flex flex-col items-center justify-center w-full">
				<div className="pt-20">
					<button
						onClick={() => window.history.back()}
						type="button"
						class="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
					>
						Torna indietro
					</button>
				</div>
				<div className="flex flex-col h-[80vh] px-2 ">
					<label
						class="block mb- text-xl font-medium text-gray-900 "
						for="file_input"
					>
						Carica File
					</label>
					<div className="flex w-full items-center  space-x-5">
						<input
							onChange={(e) => setFile(e.target.files[0])}
							className=" w-full h-auto  text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
							id="file_input"
							type="file"
						></input>

						<button
							onClick={(e) => upload(e)}
							type="button"
							class="text-white w-24 bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
						>
							Carica
						</button>
					</div>
					<div className="flex flex-col mt-7 justify-center items-center">
						{Data.map((item, i) => {
							return (
								<div className="relative my-2 w-full flex justify-between items-center bg-gray-200 px-5 py-3 rounded-xl shadow-xl border border-gray-300">
									<button
										
									>
										<p>{item.name}</p>
									</button>

									<div className="place-self-center">
										<button
											className="cursor-pointer mr-1 w-20	 bg-blue-500 text-white p-2 rounded-xl hover:bg-blue-500"
											onClick={() => {
												setPDF(item.url);
											}}
										>
											Apri
										</button>
										<button
											className="cursor-pointer ml-1 w-20	 bg-red-700 text-white p-2 rounded-xl hover:bg-red-800"
											onClick={() => setCanc(i)}
										>
											Cancella
										</button>
									</div>
								</div>
							);
						})}
					</div>
					{canc > -1 && (
						<div className="bg-gray-800 fixed w-full h-[100vh] top-0 bg-opacity-40	">
							<div
								id="popup-modal"
								tabindex="-1"
								class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 p-4 overflow-x-hidden overflow-y-auto max-h-full"
							>
								<div class="relative w-full max-w-md max-h-full">
									<div class="relative bg-white rounded-lg shadow ">
										<button
											onClick={() => setCanc(-1)}
											type="button"
											class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
											data-modal-hide="popup-modal"
										>
											<svg
												aria-hidden="true"
												class="w-5 h-5"
												fill="currentColor"
												viewBox="0 0 20 20"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
													clip-rule="evenodd"
												></path>
											</svg>
											<span class="sr-only">Close modal</span>
										</button>
										<div class="p-6 text-center flex flex-col items-center w-full">
											<img src={Data[canc].url} alt="" className="w-48" />
											<h3 class="my-5 text-lg font-normal text-gray-900 ">
												Sei sicuro di voler cancellare questo file?
											</h3>
											<div>
												<button
													onClick={() => elimina(canc)}
													data-modal-hide="popup-modal"
													type="button"
													class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
												>
													Si, sono sicuro
												</button>
												<button
													onClick={() => setCanc(-1)}
													data-modal-hide="popup-modal"
													type="button"
													class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 "
												>
													No, annulla
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="w-full">
				{PDF.length > 0 && (
					<object
						data={PDF}
						type="application/pdf"
						width="100%"
						height="100%"
						className="max-w-[70rem]"
					>
						<p>
							Clicca qui per vedere il
							<a href={PDF}>PDF!</a>
						</p>
					</object>
				)}
			</div>
		</div>
	);
}

export default Giornalino;
