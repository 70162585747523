import React from "react";

function Piazza() {
	return (
		<div className="flex flex-col justify-center">
			<h1 className="place-self-center uppercase text-4xl md:text-6xl font-semibold my-4">
				Piazza e chiesa{" "}
			</h1>
			<div className="text-justify px-12 md:px-24">
				<p className="my-2">
					La Piazza, nata nella zona di Mondrone dei Colomba, nel 300 era un
					piazzale abbastanza largo che si apriva sulla via pubblica, luogo ove
					si batteva la segala (chiamato Airal), si celebrava la festa annuale
					con balli campestri e dove si fermavano i pastori con i greggi per
					andare agli alpeggi del Piano della Mussa. Rimase tale fino a che fu
					allargata nei primi anni del 900 con notevole sforzo finanziario
					sostenuto dai Mondronesi.
				</p>
				<p className="my-2">
					Sulla piazza del paese si affaccia la Chiesa di Mondrone, dedicata ai
					Santi Apostoli Pietro e Paolo, diventata parrocchia dopo lunghe
					traversie staccandosi da quella di Ala nel 1620.
				</p>
				<p className="my-2">
					La Chiesa attuale venne edificata nel 1768 quando il vecchio edificio
					costruito solo 150 anni prima minacciava di crollare. In
					quell'occasione i Mondronesi decisero di ricostruirla completamente
					dalle fondamenta e gli abitanti affrontarono grandi sacrifici per
					vedere compiuta l'opera. Ultimata la costruzione il 19 agosto 1769
					l'arcivescovo Luserna Rorengo di Rorà benedì la nuova Chiesa. La
					parrocchia di Mondrone ottenne la completa autonomia, con decreto del
					Rorà, il quale nominò il primo parroco don Giuseppe Ignazio Monino.
				</p>
				<p className="my-2">
					Sul lato a mezzogiorno della Chiesa vi è una meridiana fatta
					realizzare dall'Economo Don Giacomo Bricco di Martassina che resse la
					parrocchia dal 1850 al 1859.
				</p>
				<p className="my-2">
					La Chiesa all’interno presenta decorazioni che furono eseguite nel
					1921 dal Cav. Peracchione Lor del Pian del Tetto e un’icona sopra
					l’altare che rappresenta i Santi titolari dipinta da Enrico Canova di
					Torino nel 1927.
				</p>

				<p className="my-2 font-semibold">
					Un’approfondita descrizione della Chiesa è presente al seguente{" "}
					<a
						target="_blank"
						rel="noreferrer"
						className="text-blue-500 hover:underline cursor-pointer"
						href="http://www.chieseitaliane.chiesacattolica.it/chieseitaliane/AccessoEsterno.do?mode=guest&code=48905&Chiesa_dei_Santi_Pietro_e_Paolo_Apostoli__Mondrone,_Ala_di_Stura"
					>
						link
					</a>
					.
				</p>
			</div>
		</div>
	);
}

export default Piazza;
