import React, { useEffect, useState } from "react";
import MegaToggleCirc from "../../Components/Molecules/Megatoggle/MegaToggleCirc";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import Megatoggle3 from "../../Components/Molecules/Megatoggle/Megatoggle3";
import Megatoggle4 from "../../Components/Molecules/Megatoggle/Megatoggle4";
import Chisiamo from "./Chisiamo";
import Cinquexmille from "./Cinquexmille";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { FaExternalLinkAlt } from "react-icons/fa";

function Circolo() {
	const [Data, setData] = useState([]);
	const [ID, setID] = useState([]);
	const [password, setPassword] = useState("");

	const cookie = new Cookies();

	const handleLoad = async () => {
		const querySnapshot = await getDocs(collection(db, "novita"));
		const docs = querySnapshot.docs;
		setID(docs.map((doc) => doc.id));
		setData(docs.map((doc) => doc.data()));
	};
	useEffect(() => {
		handleLoad();
	}, []);
	const slug = useLocation().hash;

	const handleSubmit = () => {
		if (password === "titin") {
			cookie.set("socio", "titin");
			window.location.href = "/soci";
		}
	};

	useEffect(() => {
		if (slug === "#5x1000") {
			document.getElementById("5x1000").scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "nearest",
			});
		}
	}, []);

	return (
		<div className="my-7 w-full">
			<div className="flex justify-center items-center mx-6">
				<h1 className="text-center md:text-6xl text-4xl uppercase font-semibold my-4 mx-3">
					Il Circolo Mondronese
				</h1>
				<img
					className="md:w-28 w-24"
					src={require("../../Assets/logo-circolo.jpg")}
					alt=""
				/>
			</div>
			<div className="md:px-12 grid md:grid-cols-2 place-items-center grid-cols-1 mt-12 gap-y-6 md:gap-x-6 mx-6">
				<div className="border border-gray-300 shadow-lg rounded-xl w-full py-6">
					<Chisiamo />
				</div>

				<section className="flex flex-col items-center gap-8 w-full">
					<MegaToggleCirc />
					<Megatoggle4 />
					<Megatoggle3 />
				</section>

				<section
					id="5x1000"
					className="border border-gray-300 shadow-lg rounded-xl w-full md:w-auto py-6"
				>
					<Cinquexmille />
				</section>

				<div className="md:ml-24 mt-7">
					<h3 className="text-xl pb-5">La nostra pagina facebook</h3>
					<iframe
						title="boh"
						className="md:w-[500px] md:h-[600px] w-96 h-[500px] border-0 overflow-hidden rounded-3xl shadow-xl"
						src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCircoloMondronese%2F&tabs=timeline&width=500&height=750&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
						frameborder="0"
						allowfullscreen="true"
						allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
					></iframe>
					<div className="my-[15%] flex items-center justify-center space-x-5 hover:underline bg-[#1d4ed8] py-3 rounded-lg text-white">
						<button
							className="text-3xl flex items-center justify-center space-x-5 cursor-pointer"
							onClick={() => (window.location.href = "/foto#Circolo")}
						>
							{" "}
							<p>GALLERIA FOTOGRAFICA</p>{" "}
							<FaExternalLinkAlt className="text-2xl" />
						</button>
					</div>
				</div>

				<div className="md:block hidden"></div>
				<div className="w-full flex flex-col items-center justify-center md:col-span-2">
					<h3 className="md:text-4xl text-2xl">AREA RISERVATA AI SOCI</h3>
					<input
						className="my-5 border border-gray-300 shadow-lg rounded-lg p-4"
						type="password"
						name=""
						id=""
						value={password}
						onChange={(e) => setPassword(e.currentTarget.value)}
						placeholder="Password"
					/>
					<button
						onClick={handleSubmit}
						type="submit"
						className="bg-blue-500 p-4 shadow-lg rounded-lg text-white font-medium"
					>
						Entra
					</button>
				</div>
			</div>
		</div>
	);
}

export default Circolo;
