import React from "react";

function Fauna() {
	return (
		<div className="my-12 px-12 md:px-24">
			<h1 className="text-center text-4xl md:text-6xl uppercase font-semibold my-4">
				Aspetti naturalistici
			</h1>
			<h2 className="text-center text-3xl uppercase font-semibold">Fauna</h2>
			<div className="text-justify">
				<p className="mt-7">
					Le condizioni ambientali e climatiche varie che caratterizzano la Val
					d’Ala costituiscono il presupposto fondamentale per una ricchezza
					faunistica. Un tempo i boschi della valle ospitavano una fauna oramai
					estinta in quasi tutto l'arco alpino. Notizie di ciò ci vengono dai
					registri di vari comuni della zona che riferiscono delle cacce
					compiute dai duchi di Savoia nelle valli di Lanzo. Si parla di battute
					di caccia a orsi e cinghiali risalenti a prima del 1700. Anche il
					cinghiale risulterebbe estinto dal XVII secolo; solo la recente
					introduzione a scopo venatorio di cinghiali di allevamento ha
					introdotto un'esplosione geografica di questa specie che oggi
					determina difficoltà di gestione.
				</p>
				<p className="mt-7">
					Nei boschi la presenza animale più evidente è l'avifauna. Tra le
					specie più rappresentative le cince, il fringuello, lo scricciolo, i
					luì, la capinera, gli zigoli e il crociere, dal classico becco
					incrociato che utilizza per aprire le pigne. Anche molti piccoli
					mammiferi popolano questo ambiente, soprattutto ghiri, scoiattoli e
					altri piccoli roditori, che insieme ai piccoli uccelli rappresentano
					un'importante risorsa alimentare per i predatori del bosco: ricordiamo
					tra i mammiferi la faina e tra gli uccelli l'astore, lo sparviero e la
					poiana. La morfologia del territorio, caratterizzata da numerose
					grotte, favorisce la presenza di animali difficilmente incontrabili ma
					di grande importanza ecologica: i pipistrelli.{" "}
				</p>
				<p className="mt-7">
					Oltre il limite della vegetazione arborea il pascolo alpino
					caratterizza il paesaggio. Piccoli passeriformi quali il sordone, il
					culbianco, l'allodola e il codirossone dividono le risorse di questo
					ambiente con le marmotte e i grandi ungulati alpini. La colonia di
					stambecchi presente in valle di Ala, la più grande di quelle presenti
					nelle valli di Lanzo, è l'unica, oltre a quella francese del Parco de
					La Vanoise, che ha colonizzato spontaneamente un territorio esterno al
					Parco Nazionale del Gran Paradiso. Non è difficile osservarli salendo
					dalle Grange della Mussa verso il lago del Rhu.
				</p>
				<p className="mt-7">
					Le marmotte sono la classica preda dell'aquila reale che, pur
					frequentando i pascoli come territori di caccia, nidifica nelle pareti
					rocciose di media quota. Questo grande rapace occupa territori di
					caccia molto vasti, circa 100 kmq; questo fatto limita il numero di
					coppie presenti nella valle a poche unità. Malgrado il basso numero e
					la loro grande mobilità, le notevoli dimensioni rendono questa specie
					facilmente osservabile e riconoscibile. In particolare nei mesi
					invernali è possibile osservarne diversi individui contemporaneamente.
				</p>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-5 items-center justify-items-center mt-7">
				<div>
					<img
						className="w-96"
						src={require("../../../Assets/aquila.jpg")}
						alt=""
					/>
				</div>
				<div>
					<img
						className="w-96"
						src={require("../../../Assets/stambecchi_Balme_repertorio_4_OK.jpg")}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}

export default Fauna;
