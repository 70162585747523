import React from 'react'
import 'animate.css';


function Title() {
  return (
    <div className='w-full aspect-[2.78/1] relative bg-cover bg-[url("./Assets/vallata.jpg")]'>
        <h1 className='text-white font-bold md:text-[125px] text-[26px] absolute md:top-[-2vw] md:left-[27vw] left-[31vw] z-30 animate__animated animate__fadeInUp'>MONDRONE</h1>
        <img className='absolute w-full aspect-[2.78/1] object-cover bottom-0 z-50 ' src={require('../../../Assets/vallataT.png')} alt="" />
    </div>
  )
}

export default Title