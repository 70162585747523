import React, { useEffect, useState } from "react";
import Login from "./Login";
import IsLogged from "./IsLogged";

function Admin() {
    const [logged, setLogged] = useState(IsLogged());

    return (
        <div>
            {!logged ? (
                <Login setLogged={setLogged} />
            ) : (
                <div className="w-full h-[70vh] flex md:flex-row flex-col items-center justify-evenly">
                    <button
                        onClick={() => (window.location.href = "/caricafoto")}
                        type="button"
                        className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                    >
                        Foto
                    </button>
                    <button
                        onClick={() => (window.location.href = "/caricaarchivio")}
                        type="button"
                        className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                    >
                        Archivio
                    </button>
                    <button
                        onClick={() =>
                            (window.location.href = "/caricaverbali")
                        }
                        type="button"
                        className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                    >
                        Verbali Riunione
                    </button>
                    <button
                        onClick={() =>
                            (window.location.href = "/caricabilanci")
                        }
                        type="button"
                        className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                    >
                        Bilanci
                    </button>
                    <button
                        onClick={() =>
                            (window.location.href = "/caricagiornalino")
                        }
                        type="button"
                        className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                    >
                        Giornalino
                    </button>
                    <button
                        onClick={() => (window.location.href = "/modnews")}
                        type="button"
                        className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                    >
                        NEWS
                    </button>
                </div>
            )}
        </div>
    );
}

export default Admin;
