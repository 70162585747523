import React from "react";

function Meridiane() {
	return (
		<div className="my-12 md:px-24 px-6">
			<h1 className="text-center text-4xl md:text-6xl uppercase font-semibold my-4">
				meridiane e affreschi
			</h1>
			<div className="text-justify">
				<p className="mt-7">
					Mondrone non è solo un luogo di bellezze naturali ma anche un custode
					di preziosi tesori artistici. Tra questi spiccano le meridiane e gli
					affreschi che adornano le facciate di alcune delle case e degli
					edifici storici della frazione. Questi elementi artistici non solo
					arricchiscono il paesaggio, ma raccontano anche storie secolari e
					tradizioni che riflettono la cultura e la storia locale.
				</p>
				<p className="mt-7">
					Le meridiane, strumenti antichi usati per misurare il tempo attraverso
					l'ombra proiettata dal sole, hanno una lunga tradizione nelle comunità
					montane come Mondrone. Oltre alla loro funzione pratica, le meridiane
					sono anche simboli di riflessione sul passaggio del tempo e sulla vita
					umana.
				</p>
				<p className="mt-7">
					A Mondrone alcune vecchie e caratteristiche case recano pitture,
					eseguite da Andrea Bogliano nel 1876; un affresco rappresenta la fuga
					in Egitto; un altro una madonna con bambino del Mantovani datato 1956.
					Le pitture migliori sono quelle che decorano internamente la Chiesa e
					che furono eseguite dal Cav. Luigi Peracchione-Lor per commissione del
					prevosto.
				</p>
				<div className="flex md:flex-row flex-col gap-7 items-center mt-7">
                    <div>

				<p className="">
					Sono anche visibili due belle meridiane ora ristrutturate. La prima fu
					fatta dipingere nell’ottocento dall’Economo Don G. Bricco sul lato a
					mezzogiorno della chiesa, sopra la porta della sacrestia, e fu per
					molti anni di pubblica utilità segnando l’ora quando gli orologi erano
					ancora sconosciuti. La seconda è situata sulla facciata della
					penultima casa del paese circondata tre dipinti; un paesaggio con
					sullo sfondo sette stelle (Septentriones” [sette buoi]: l’Orsa
					Maggiore), l’immagine della Consolata e quella dell’apostolo San
					Pietro.
				</p>
					<p className="mt-7">
						Passeggiare per le strade di Mondrone e osservare le meridiane è
						come fare un viaggio nel tempo. Negli ultimi anni, c'è stato un
						crescente interesse per la conservazione e la valorizzazione di
						questi tesori artistici, come il progetto “Ala di Stura, il paese
						delle meridiane e degli affreschi”, documentato nel sito{" "}
						<a
							rel="noreferrer"
							className="hover:underline text-blue-500 cursor-pointer"
							target="_blank"
							href="https://progettomeridiane.comunealadistura.it/"
                            >
							progettomeridiane.comunealadistura.it
						</a>
						. Per approfondimenti su Mondrone clicca{" "}
						<a
							rel="noreferrer"
							className="hover:underline text-blue-500 cursor-pointer"
							href="https://progettomeridiane.comunealadistura.it/itinerari/itinerario-5c/"
                            >
							qui
						</a>
						.
					</p>
                            </div>
					<img
						src={require("../Assets/cartina meridiane.PNG")}
						alt="meridiane"
						className="max-w-full h-auto"
					/>
				</div>
				<p className="mt-7">
					Le meridiane e gli affreschi rappresentano un patrimonio artistico e
					culturale di valore. La loro presenza testimonia la ricchezza della
					cultura locale e l'abilità artistica degli artigiani del passato,
					offrendo a residenti e visitatori un motivo in più per esplorare e
					apprezzare la frazione di Mondrone.
				</p>
			</div>
		</div>
	);
}

export default Meridiane;
