import React from "react";

function Card({data, id}) {
	return (
		<div className="md:h-96 flex items-center justify-center md:w-72 md:mx-auto">
			<div className="my-7 mx-1 bg-white rounded-lg shadow">
				<a href={'/news/'+id}>
					<img
						className="rounded-t-lg h-44 w-96 object-cover"
						src={data.img}
						alt=""
					/>
				</a>
				<div className="p-5 h-56 relative">
					<a href={'/news/'+id}>
						<h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 ">
							{data.titolo}
						</h5>
					</a>
					<p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">
						{data.descr}
					</p>
					<a
						href={'/news/'+id}
						className="inline-flex absolute bottom-5 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
					>
						Leggi di più
						<svg
							aria-hidden="true"
							className="w-4 h-4 ml-2 -mr-1"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
								clip-rule="evenodd"
							></path>
						</svg>
					</a>
				</div>
				<p className="text-right text-sm font-thin pr-1">{data.date}</p>
			</div>
		</div>
	);
}

export default Card;
