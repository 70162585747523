import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { db } from "../../firebase";
import BasicFAQ from "../../Components/Molecules/Griglie/BasicFAQ";

function Soci() {
	const cookie = new Cookies();
	const [PDF, setPDF] = useState("");
	const [Data, setData] = useState([]);
	const [ID, setID] = useState([]);
	const [URL, setURL] = useState("");

	const handleLoad = async (url) => {
		setURL(url);
		const querySnapshot = await getDocs(collection(db, url));
		const docs = querySnapshot.docs;
		setID(docs.map((doc) => doc.id));
		setData(docs.map((doc) => doc.data()));
		console.log(docs.map((doc) => doc.data()));
		setPDF("");
	};
	const [loaded, setLoaded] = useState(0);
	useEffect(() => {
		if (cookie.get("socio") !== "titin") {
			window.location.href = "/circolo";
		} else {
		}
	}, []);

	return (
		<div>
			<h1 className="text-center md:text-6xl text-4xl uppercase font-semibold my-4 mx-3">
				Pagina riservata ai soci
			</h1>
			<div className="w-full h-[40vh] flex md:flex-row flex-col items-center justify-evenly">
				<button
					onClick={() => {
						handleLoad("verbali");
						setLoaded(!loaded);
					}}
					type="button"
					className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xl px-5 py-2.5 mr-2 mb-2 "
				>
					Verbali Riunione
				</button>
				<button
					onClick={() => {
						handleLoad("bilanci");
						setLoaded(!loaded);
					}}
					type="button"
					className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xl px-5 py-2.5 mr-2 mb-2 "
				>
					Bilanci
				</button>
				<button
					onClick={() => {
						handleLoad("giornalino");
						setLoaded(!loaded);
					}}
					type="button"
					className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xl px-5 py-2.5 mr-2 mb-2 "
				>
					Giornalino
				</button>
			</div>
			<div className={`w-full h-[100vh] px-6 ${loaded ? "block" : "hidden"}`}>
				<h2 className="text-4xl font-semibold capitalize my-10">{URL}</h2>
				<div className="grid md:grid-cols-2 grid-cols-1 gap-10 h-[100vh]">
					<div>
						{Data.map((item, i) => {
							return (
								<div className="relative my-2 w-full flex justify-between items-center bg-gray-200 px-5 py-3 rounded-xl shadow-xl border border-gray-300">
									<button>
										<p className="text-xl">{item.name}</p>
									</button>

									<div className="place-self-center">
										<button
											className="cursor-pointer mr-1 w-20	 bg-blue-500 text-white p-2 rounded-xl hover:bg-blue-500"
											onClick={() => {
												setPDF(item.url);
											}}
										>
											Apri
										</button>
									</div>
								</div>
							);
						})}
					</div>
					{PDF.length > 0 && (
						<object
							data={PDF}
							type="application/pdf"
							width="100%"
							height="100%"
							className="max-w-[70rem]"
						>
							<p>
								Clicca qui per vedere il
								<a href={PDF}>PDF!</a>
							</p>
						</object>
					)}
				</div>
			</div>
			<div>
				<BasicFAQ />
			</div>
		</div>
	);
}

export default Soci;
