import React from "react";

function Arrivare() {
	return (
		<div>
			<h1 className="text-center md:text-6xl text-4xl uppercase font-semibold my-4">
				arrivare a mondrone
			</h1>
			<div className="grid grid-cols-1 md:grid-cols-2">
				<div className="md:px-24 px-12 my-12 ">
					<h2 className="text-2xl font-semibold">Con i mezzi pubblici</h2>
					<h3 className="font-medium text-lg my-5">
						Informazioni utili si trovano ai seguenti link:
					</h3>
					<div className="flex flex-col items justify-center space-y-5">
						<a
							target="_blank"
							rel="noreferrer"
							className="text-blue-500 hover:underline"
							href="https://www.gtt.to.it/cms/linee-e-orari/torino-e-cintura/index.php?option=com_content&view=article&id=47&catid=8&lang=it&Itemid=201"
						>
							Linea ferroviaria Torino Ceres: dal 12 giugno 2023 il treno
							diventa bus
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							className="text-blue-500 hover:underline"
							href="https://moovitapp.com/index/it/mezzi_pubblici-line-188-Torino-222-1130883-2109487-1#google_vignette"
						>
							Orari e fermate della linea Cavourese S.p.a. Bus 188 (Ceres-Balme)
						</a>
					</div>
					<div className="flex mt-12 flex-col items-center justify-center">
						<img
							src={require("../../Assets/cartina_valli_di_lanzo.jpg")}
							alt=""
						/>
						<img
							src={require("../../Assets/cartello.jpg")}
							alt=""
							className="w-[90%] mt-12"
						/>
					</div>
				</div>
				<div className="grid grid-cols-1">
					<div className="md:px-24 px-12 my-12 text-justify">
						<h2 className="text-2xl font-semibold">In auto</h2>
						<p className="my-4">
							Da Torino le due strade provinciali, la SP1 e SP2 permettono di
							raggiungere le Valli di Lanzo ed i Comuni montani. La Strada
							Provinciale 1, che da Venaria Reale costeggia il Parco Regionale
							della Mandria, converge nel Comune di Cafasse e da qui alla
							diramazione per Lanzo Torinese o per Germagnano e quindi per le
							tre Valli di Viù, d'Ala e Grande. La Strada Provinciale 2 invece,
							che parte da Caselle Torinese, attraversa poi i comuni di Ciriè,
							Nole, Mathi fino a raggiungere il primo Comune della Comunità
							Montana Valli di Lanzo che è Balangero. Da qui sempre percorrendo
							la SP2 si raggiunge facilmente Lanzo T.se e da lì si prosegue fino
							a Germagnano, punto di partenza per le tre valli.
						</p>
						<p className="my-4">
							A Germagnano, a causa della chiusura della galleria Montebasso da
							inizio luglio 2023, è necessario percorrere la Strada Provinciale
							2 (Torino-Germagnano) passando per il centro abitato del paese.
						</p>
						<p className="my-4">
							Riprendendo quindi la Strada Provinciale 1 verso l'alta valle,
							attraversato l'abitato di Pessinetto, si giunge al bivio per
							Mezzenile, si prosegue dritto arrivando ad un secondo bivio con
							direzione Ceres, Ala di Stura, Balme, Pian della Mussa oppure
							direzione Cantoira, Chialamberto, Forno. Al bivio si prosegue
							dritto in direzione Ceres costeggiando la ferrovia e una volta
							entrati in Ceres si segue la strada che piega a sinistra per la
							Valle d'Ala. Da Ceres la strada è unica, si attraversano le
							frazioni di Voragno e Brachiello, fino a raggiungere il Comune di
							Ala di Stura, poi si prosegue ancora, attraversando Cresto e
							Martassina, per giungere infine a Mondrone.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Arrivare;
