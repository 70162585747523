import React from "react";
import Albaron from "../../Assets/Convenzioni/Abaron.png";

function Convenzioni() {
	const conv = [
		{
			img: "discorotto.PNG",
			sconto: "10",
			text: "",
			link: "https://www.google.com/maps/place/Discorotto+(pizzeria+cocktail+bar)/@45.3130775,7.3071336,17z/data=!3m1!4b1!4m6!3m5!1s0x4788558ff47c1c15:0xc11736be2b7fd912!8m2!3d45.3130775!4d7.3071336!16s%2Fg%2F11t6lqxr5t?entry=ttu",
			address: "Via Circonvallazione 42 – Ala di Stura",
			tel: " 3403560290",
		},
		{
			img: "Abaron.png",
			sconto: "20",
			text: "",
			link: "https://goo.gl/maps/NEXQou9cRs7x2tq48",
			address: "Villaggio Albaron – Balme",
			tel: "3356095687",
		},
		{
			img: "GrandHotel.png",
			sconto: "10",
			text: "(valido per massimo 4 persone ogni tessera)",
			link: "https://goo.gl/maps/e1PLFesbhoMeq6gp6",
			address: "Via Pian del Tetto 2, - Ala di Stura",
			tel: "012355189",
		},
		{
			img: "LaMasina.png",
			sconto: "10",
			text: "",
			link: "https://goo.gl/maps/8HxC6UNonhVbh8mA8",
			address: "Località Pian della Mussa – Balme",
			tel: "3474439384",
		},
		{
			img: "PeakLand.png",
			sconto: "10",
			text: "",
			link: "https://goo.gl/maps/4hS6Xjfy9gVFca9t7",
			address: "Piazza Centrale, 26 – Ala di Stura",
			tel: "3338436900",
		},
		{
			img: "Uja.png",
			sconto: "10",
			text: "Utilizzo esclusivo con prenotazione per 2h dell’area esterna relax con sauna ed idromassaggio. Inoltre, sarà possibile utilizzare i servizi igienici e la doccia presenti in struttura.",
			link: "https://goo.gl/maps/qtk8VhvuLSZn7DpU9",
			address: "Frazione Mondrone – Ala di Stura",
			tel: "3385354025",
		},
	];
	return (
		<div className="flex my-7 flex-col items-center justify-center">
			<h1 className="text-center md:text-6xl text-4xl uppercase font-semibold my-4">
				Convenzioni
			</h1>
			<p className="px-7">
				Per il 2024 le CONVENZIONI di cui possono usufruire i SOCI grazie al
				RINNOVO della QUOTA SOCIALE sono:
			</p>
			<div className="grid grid-cols-1 my-4">
				{conv.map((item, index) => (
					<div key={index} className="grid grid-cols-2 md:gap-x-52 my-4 md:w-[900px]  border-b border-gray-700">
						<div className="md:h-32 h-20 md:w-96 w-40">
							<img
								src={require("../../Assets/Convenzioni/" + item.img)}
								className="mix-blend-multiply w-full h-full object-contain "
								alt=""
							/>
						</div>
						<div className="flex flex-col justify-center">
							{item.sconto && (
								<p className="font-bold">Sconto del {item.sconto}%</p>
							)}
							<p className="">{item.text}</p>
							<a
								rel="noreferrer"
								target="_blank"
								href={item.link}
								className="hover:underline hover:text-blue-500 cursor-pointer"
							>
								{item.address}
							</a>
							<a
								href={"tel:" + item.tel}
								className="hover:underline hover:text-blue-500 cursor-pointer"
							>
								Tel. {item.tel}
							</a>
						</div>
					</div>
				))}
				<div className="grid grid-cols-2 md:gap-x-52 my-4 md:w-[900px]">
					<div className="md:h-32 h-20 md:w-96 w-40">
						<img
							src={require("../../Assets/Convenzioni/Epifani.png")}
							className="mix-blend-multiply w-full h-full object-contain "
							alt=""
						/>
					</div>
					<div className="flex flex-col justify-center">
						<p className="">
						<strong>Sconto dal 15% al 20%</strong> su ottica e gioielleria ed in alcuni periodi
							lo sconto passa al <strong>50%</strong> sulle lenti da vista.
						</p>
						<a
							rel="noreferrer"
							target="_blank"
							href="https://goo.gl/maps/n6xn8iqr6SY1m4Ed7"
							className="hover:underline hover:text-blue-500 cursor-pointer"
						>
							Via Livorno, 9 – Torino
						</a>
						<a
							href={"tel:0114733613"}
							className="hover:underline hover:text-blue-500 cursor-pointer"
						>
							Tel. 0114733613
						</a>
					</div>
				</div>
			</div>
			<p className="px-12 text-center">
				Per un miglior servizio, in tutti i ristoranti è gradita la prenotazione
			</p>
		</div>
	);
}

export default Convenzioni;
