import React from "react";
import Slider from "react-slick";

function Pianard() {
	const CustomPrevArrow = (props) => {
		const { className, onClick } = props;
		return (
			<div className={className + "relative "} onClick={onClick}>
				<svg
					className="absolute left-2 z-20 top-1/2 -translate-y-1/2 rotate-180 cursor-pointer hover:scale-90 transition-all duration-300"
					height="40px"
					width="40px"
					version="1.1"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 512 512"
					xmlSpace="preserve"
				>
					<g id="SVGRepo_bgCarrier" strokeWidth="0" />
					<g
						id="SVGRepo_tracerCarrier"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<g id="SVGRepo_iconCarrier">
						<g>
							<g>
								<path
									d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.616,256-256S397.385,0,256,0z M346.899,280.959 l-85.594,85.594c-13.783,13.784-36.132,13.784-49.917,0c-13.784-13.784-13.784-36.133,0-49.917L272.023,256l-60.635-60.635 c-13.784-13.784-13.784-36.133,0-49.917s36.134-13.784,49.917,0l85.594,85.594C360.683,244.825,360.683,267.175,346.899,280.959z"
									fill="rgb(29 78 216)"
								/>
							</g>
						</g>
					</g>
				</svg>
			</div>
		);
	};

	// CustomNextArrow.js
	const CustomNextArrow = (props) => {
		const { className, onClick } = props;
		return (
			<div className={className + "relative"} onClick={onClick}>
				<svg
					className="absolute right-2 -translate-y-1/2 top-1/2 cursor-pointer hover:scale-90 transition-all duration-300"
					height="40px"
					width="40px"
					version="1.1"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 512 512"
					xmlSpace="preserve"
				>
					<g id="SVGRepo_bgCarrier" strokeWidth="0" />
					<g
						id="SVGRepo_tracerCarrier"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<g id="SVGRepo_iconCarrier">
						<g>
							<g>
								<path
									fill="rgb(29 78 216)"
									d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.616,256-256S397.385,0,256,0z M346.899,280.959 l-85.594,85.594c-13.783,13.784-36.132,13.784-49.917,0c-13.784-13.784-13.784-36.133,0-49.917L272.023,256l-60.635-60.635 c-13.784-13.784-13.784-36.133,0-49.917s36.134-13.784,49.917,0l85.594,85.594C360.683,244.825,360.683,267.175,346.899,280.959z"
								></path>
							</g>
						</g>
					</g>
				</svg>
			</div>
		);
	};
	var settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		autoplay: false,
		arrows: true,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
	};
	var foto = [
		"ponte nuovo 1.JPG",
		"ponte nuovo 2.JPG",
		"ponte nuovo 3.JPG",
		"ponte nuovo 4.JPG",
		"vecchio ponte pianard distrutto.JPG",
	];

	return (
		<div className="flex flex-col justify-center items-center my-5">
			<h1 className="place-self-center text-center uppercase text-4xl md:text-6xl font-semibold my-4">
				FONTE E PONTE DEL PIANARD
			</h1>
			<div className="px-12 md:px-24 text-justify">
				<p className="my-2">
					La <span className="font-semibold">fonte del Pianard</span> a Mondrone
					è la fonte per eccellenza. L’acqua è purissima. La sorgente sgorga
					sull’anverso, ossia sul versante in ombra. La temperatura è più bassa,
					la vegetazione più folta, il terreno più profondo e anche
					l’innevamento è più abbondante e duraturo per cui l’acqua risulta più
					fredda e più pura rispetto alle sorgenti collocate sull’andritto,
					ossia sul versante esposto al sole.
				</p>
				<p className="my-2">
					La sorgente del Pianard si trova oltre il fiume Stura ma per portare
					l’acqua più vicino alle case gli abitanti di Mondrone hanno costruito
					un piccolo acquedotto e un ponte.
				</p>
				<p className="my-2">
					Al caratteristico ponte del Pianard si arriva tramite un percorso
					accessibile a tutti che attraversa prati da erba e zona boschiva
					limitrofa al torrente Stura passando per il campo sportivo polivalente
					e lo spazio giochi bimbi.
				</p>
				<p className="my-2">
					Il punto di partenza del percorso è sulla piazza del paese in
					prossimità della Libera Biblioteca: si scende verso il cimitero su
					strada sterrata, percorribile prestando molta attenzione anche con
					carrozzelle e accompagnatore ed eventualmente con autovettura. Dopo il
					campo da bocce si possono seguire due percorsi: Il primo con un tratto
					di strada sterrata che poi diventa sentiero e porta alla zona
					polisportiva; il secondo costeggiando il cimitero porta alla Panchina
					Gigante da cui prosegue sulla strada sterrata e oltrepassando il campo
					da calcio si ricongiunge all’altezza del campo giochi bimbi con il
					sentiero che in lieve discesa passa accanto ai campi. Prima di
					arrivare alla Stura il sentiero volge a destra per proseguire con un
					tratto pianeggiante praticamente parallelo all’asse fluviale.
					Proseguendo sempre nel tratto in orizzontale si passa per il sentiero
					che dal paese porta verso il Vallonetto, subito sulla destra vi è
					l’area giochi ad uso di bambini inferiori ai 12 anni, mentre alzando
					lo sguardo verso la strada che porta a Balme si scorge in tutta la sua
					maestosità l’Uia di Mondrone. Proseguendo ancora sempre su sentiero
					erboso pianeggiante si giunge alla piazzola della fontana del Pianard,
					attrezzata per picnic con panca, tavolo e acqua corrente.
				</p>
				<p className="my-2">
					La fontana ha le sembianze di un camoscio di legno con le corna vere e
					si trova nel solco lasciato dall’antica “bialera”. Si intravede ancora
					il canale ormai interrato che derivava l’acqua dal torrente per
					alimentare le costruzioni in pietra dette “veilìn”, utilizzate per
					refrigerare le grandi bacinelle piene di latte e favorire
					l’affioramento della panna e dove venivano conservati al fresco i
					prodotti lavorati del latte (burro, formaggio) soggetti ad alterazione
					nel periodo estivo. Si vede ancora anche la saracinesca in legno che
					serviva per regolare l’afflusso dell’acqua.
				</p>
				<p className="my-2">
					Per arrivare alla sorgente bisogna attraversare il ponte del Pianard e
					raggiungere il lato opposto del torrente, dove si stende il Pian delle
					Masche (streghe). Qualche centinaio di metri più a monte, un ripido
					sentiero conduce alla sorgente vera e propria, in riva al torrente.
				</p>
				<p className="my-2 italic">
					(parte del testo è adattata da post fb di Giorgio Inaudi)
				</p>
			</div>
			<div className="grid md:grid-cols-2 items-center gap-5 justify-items-center mt-11 w-full">
				<div className="flex flex-col justify-center ">
					<img
						className="h-80"
						src={require("../../../Assets/Paese/fonte pianard.jpg")}
						alt=""
					/>
					<p className="hidden">Foliage autunnale</p>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-80"
						src={require("../../../Assets/Paese/pian dle masche.jpg")}
						alt=""
					/>
					<p className="hidden">Campanula Elatines</p>
				</div>
			</div>
			<div className="px-12 mt-7">
				<p className="my-2">
					Il <span className="font-semibold">ponte del Pianard</span> costruito
					in origine subì pesantissimi danni per effetto della piena della Stura
					il giorno dell'alluvione dell'ottobre 2000: rimasero soltanto parte
					dei pilastri in corrispondenza delle rampe di accesso, mentre il
					pilastro centrale e tutta la struttura in legno furono spazzati via.
				</p>
				<p className="my-2">
					Nel mese di maggio 2006 si svolsero le dovute procedure amministrative
					per dare avvio ai lavori di ricostruzione nelle vicinanze in zona
					sicura rispetto a dove era posizionato quello precedente. I lavori
					furono realizzati nell’arco di un anno e il 30 giugno 2007 si svolse
					alla presenza di autorità civili e ecclesiastiche l'inaugurazione
					ufficiale, con la partecipazione del Coro A.N.A. Sezione di Torino che
					ha allietato la cerimonia con canti alpini.
				</p>
			</div>
			<div className="md:w-[800px] w-screen mt-3">
				<Slider {...settings}>
					{foto.map((foto, index) => (
						<img
							src={require("../../../Assets/Paese/" + foto)}
							key={index}
							className=""
							alt=""
						/>
					))}
				</Slider>
			</div>
		</div>
	);
}

export default Pianard;
