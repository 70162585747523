import React from "react";

function Liberi() {
	return (
		<div className="my-12 md:px-24 px-6">
			<h1 className="text-center text-3xl md:text-5xl uppercase font-semibold my-4">
				LIB(E)RI IN MONTAGNA – Un’estate in biblioteca{" "}
			</h1>
			<div className="text-justify">
				<p className="mt-7">
					Per iniziativa del Circolo Mondronese, grazie al progetto “LIB(E)RI IN
					MONTAGNA – Un’estate in biblioteca” finanziato da Fondazione CRT, la
					LIBERA BIBLIOTECA DI MONDRONE è stata rinnovata negli ambienti e negli
					arredi, con il contributo di volontari del Circolo e i partner di
					progetto. Il Consorzio Kairos, l’Associazione Babelica, il Museo
					Nazionale della Montagna, l’Associazione L’INDICE dei libri del mese,
					il Comune di Ala di Stura, il Comune di Balme, sono gli enti che hanno
					collaborato alla scrittura e attuazione del progetto.
				</p>
				<div className=" flex md:flex-row flex-col gap-7 mt-7 items-center">
					<div>
						<p className="">
							Cuore del progetto "Lib(e)ri in montagna" sono i momenti di
							aggregazione stimolanti e costruttivi attorno al tema della
							lettura, per riscoprire la bellezza della socialità e della
							condivisione di storie, idee e pensieri, contribuendo al contempo
							a rivitalizzare il territorio. Le azioni del progetto riguardano
							l’allestimento degli spazi per preparare ambienti confortevoli e
							funzionali per accogliere le attività, riordinare e catalogare i
							libri; una rivitalizzazione della biblioteca creando occasioni
							culturali e aggregative per adulti e bambini attraverso un ricco
							calendario di eventi estivi e offrendo nuovi usi polivalenti dello
							spazio per consolidare il ruolo vitale della biblioteca nella
							comunità e infine la costituzione di un tavolo di progettazione
							per sviluppare attività e iniziative che rispecchino gli interessi
							dei partecipanti per l'estate successiva.
						</p>
						<p className="">
							Attualmente la Libera Biblioteca di Mondrone dispone di circa 2500
							volumi che sono stati riordinati sui nuovi scaffali espositivi e
							che saranno disponibili per le prossime stagioni estive.
							L’illuminazione interna è stata rinnovata con il posizionamento di
							lampade a LED a basso consumo e gli interni ora rinnovati
							accolgono spazi di lettura, postazioni di studio, un’area dedicata
							ai bambini, un’area multimediale con rete internet e TV e offrono
							un servizio territoriale importante per il tempo libero e la
							cultura della comunità.
						</p>
					</div>
					<img
						className="max-w-full md:h-80"
						src={require("../../Assets/Paese/foto progetto lib(e)ri - un_estate in montagna/biblioteca nuova 2 .jpg")}
						alt=""
					/>
				</div>

				<div className=" flex md:flex-row flex-col gap-7 my-7 items-center">
					<img
						className="max-w-full md:h-80"
						src={require("../../Assets/Paese/foto progetto lib(e)ri - un_estate in montagna/articolo biblioteca.jpg")}
						alt=""
					/>
					<div>
						<p className="">
							Da fine giugno prenderà il via una nuova stagione culturale
							pensata per coinvolgere tutta la comunità di Ala di Stura e delle
							valli circostanti, con l’offerta di un cartellone di eventi che
							spaziano tra diversi generi letterari e attività per tutte le età.
							Tra le attività in programma ci sono letture animate per bambini,
							e appuntamenti con scrittori di fama locale e nazionale. Questi
							incontri offriranno ai partecipanti l'opportunità di esplorare
							mondi letterari diversi e di interagire direttamente con gli
							autori.
						</p>

						<p className="mt-7">
							La biblioteca di Mondrone, ora rinnovata, si propone come un luogo
							di incontro e di condivisione culturale per tutta la comunità. I
							bambini possono godere di spazi dedicati a loro, dove possono
							avvicinarsi alla lettura attraverso attività ludiche e didattiche.
							Gli adulti, invece, hanno a disposizione aree tranquille per la
							lettura e lo studio, oltre alla possibilità di partecipare ad
							eventi culturali e ludici.
						</p>
					</div>
				</div>
			</div>
			<div className="flex flex-col md:flex-row gap-7 items-center justify-around mt-10">
				<div className="">
					<img
						className="max-w-full h-auto grow-0 shrink-0"
						src={require("../../Assets/Paese/foto progetto lib(e)ri - un_estate in montagna/biblioteca nuova 1 .jpg")}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}

export default Liberi;
