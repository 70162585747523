import React from "react";

function Gastaldi() {
	return (
		<div className="grid md:grid-cols-2 grid-cols-1 gap-6 place-items-center md:px-24 px-12">
		<a  className="md:col-span-2 my-7" href="" target="_blank" rel="noreferrer">
				<h1 className="text-4xl text-center font-smeibold uppercase hover:underline">
					rifugio Gastaldi
				</h1>
			</a>
			<iframe
				src="https://bessanese.panomax.com"
				className="w-full h-[650px] col-span-2"
				allowfullscreen
			></iframe>
		</div>
	);
}

export default Gastaldi;
