import React from "react";

function Gite() {
	return (
		<div className="text-justify">
			<h1 className="text-center md:text-6xl text-4xl uppercase font-semibold my-4">
				Gite ed escursioni
			</h1>
			<div className="px-12 my-5">
				<h3 className="mt-2">
					Da Mondrone sono possibili escursioni di differenti livelli di
					complessità:
				</h3>
				<ul className="list-disc">
					<li className="mb-3 mt-1">
						<span className="font-semibold">
							{" "}
							<a className="hover:underline cursor-pointer" href="/al_ba">
								Sentiero AL-BA
							</a>
						</span>
						:{" "}
						<span>
							Mondrone si trova esattamente a metà del sentiero tra Ala di Stura
							e Balme per cui l’escursionista può scegliere di dirigersi verso
							la cittadina più turistica di Ala di Stura dove può trovare varie
							attività commerciali, locali e punti ristoro oppure può salire
							verso Balme, noto come il paese delle guide alpine, e godere della
							tranquillità di paesaggi ancora di natura selvaggia e piccolo
							borgo alpino. Il Comune di Balme nel giugno 2023 è stato infatti
							premiato con una delle cinque bandiere verdi assegnate da
							Legambiente all’arco alpino proprio per la scelta di preservare la
							natura e il paesaggio tradizionale.
						</span>
						<img
							src={require("../Assets/tabellone.jpg")}
							className="h-[500px] aspect-auto hidden"
							alt=""
						/>
					</li>
					<li className="my-3">
						<span className="font-semibold">
							{" "}
							<span >
								Il giro dei 3 ponti: ponte della Gorgia, ponticello dei laghetti
								e ponte del Pianard
							</span>
						</span>
						:{" "}
						<span>
							Si parte dalla{" "}
							<a
								className="text-blue-500 hover:underline"
								href="/luoghi/piazza"
							>
								piazza di Mondrone
							</a>{" "}
							dove si può parcheggiare comodamente l’automobile, si scende verso
							il forno del paese da cui parte il sentiero (GTA 209A), si
							attraversano i prati camminando sul sentiero e si arriva al primo
							ponte della{" "}
							<a className="text-blue-500 hover:underline" href="/gorgia">
								Gorgia
							</a>
							. Proseguendo il sentiero sale un pochino, si giunge nel
							sottobosco, si cammina costeggiando il torrente Stura e si arriva
							al ponte dei laghetti da cui si possono vedere due laghetti
							d’acqua cristallina. Poi si prosegue sempre nel sottobosco, si
							affronta un lieve saliscendi fino al Pian d’le masche e si giunge
							al ponte del{" "}
							<a
								className="text-blue-500 hover:underline"
								href="/luoghi/pianard"
							>
								Pianard
							</a>
							.
						</span>
					</li>
					<li className="my-3">
						<span className="font-semibold">
							{" "}
							<span >
								Passeggiata verso l’Alpe Pian Prà
							</span>
						</span>
						:{" "}
						<span>
							Attraverso la strada bianca (non aperta al traffico veicolare) che
							supera l’abitato di Mondrone si sviluppa una strada con lunghi
							tornanti e porta all’alpeggio Pian Prà. L’escursionista può godere
							della vista di ampi scorci sulla vallata. L’ultimo tratto
							costeggia il sentiero GTA 233 ed è percorribile nella stagione
							invernale con le ciaspole.
						</span>
					</li>
					<li className="my-3">
						<span className="font-semibold">
							{" "}
							<span >
								Passeggiata verso il Laietto
							</span>
						</span>
						:{" "}
						<span>
							Scendendo dall’abitato di Mondrone e tenendo la sinistra si
							percorre la vecchia strada ormai chiusa che collegava Mondrone a
							Martassina che è attualmente percorribile solo a piedi. Si
							incontra una palina con indicazioni per raggiungere prima il borgo
							dei I Sardi e poi il Laietto. La strada si sviluppa senza grosse
							difficoltà attraverso boschi di faggi e ricca vegetazione.
						</span>
					</li>
				</ul>
				<h3 className="mt-6 text-xl font-semi text-left" >
					Consigli utili per l’equipaggiamento e la preparazione all’escursione:
				</h3>
				<ul className="mt-1">
					<ul className="list-disc">
						<li>Controllare le previsioni meteo,</li>
						<li>Essere allenati e adeguatamente preparati fisicamente,</li>
						<li>Portare zaino con viveri,</li>
						<li>Avere con sé una borraccia d’acqua,</li>
						<li>
							Indossare calzature e abbigliamento adatti: scarponcini da
							montagna, calzettoni, pantaloni da escursionismo, maglione o
							felpa, giacca a vento, mantellina anti-pioggia, kit per il pronto
							soccorso.
						</li>
						<li>
							Se si prevede il pernottamento è consigliabile contattare
							preventivamente il rifugio e prenotare.
						</li>
						<li>
							Se possibile evitare escursioni in solitaria e comunque è sempre
							bene lasciar detto a qualcuno dove si intende arrivare e che
							percorso si seguirà.
						</li>
					</ul>
				</ul>
			</div>
		</div>
	);
}

export default Gite;
