import React, { useEffect, useState } from "react";
import Card from "../../Components/Molecules/Card/Card";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import Fissata from "../../Components/Molecules/Card/Fissata";

function News() {
	const [Data, setData] = useState([]);
	const [fissata, setFissata] = useState([]);

	const handleLoad = async () => {
		const querySnapshot = await getDocs(collection(db, "news"));
		const docs = querySnapshot.docs;
		const dati = docs.map((doc) => doc.data());
		const sorted = dati.sort((a, b) => b.date - a.date).reverse();
		setFissata(
			dati.filter((item) => item.fissato).sort((a, b) => b.fissato - a.fissato)
		);
		setData(sorted);
	};
	useEffect(() => {
		handleLoad();
	}, []);
	return (
		<div className="flex flex-col items-center justify-center">
			<h1 className="text-center font-semibold uppercase text-6xl">NEWS</h1>
			<div className="">

			{fissata.length > 0 && <Fissata data={fissata[0]} id={fissata[0].id} />}
			</div>
			<div className="md:px-24 px-12 flex flex-wrap justify-around items-center">
				{Data.map((item, index) => {
					return <Card key={index} data={item} id={item.id} />;
				})}
			</div>
		</div>
	);
}

export default News;
