import React from "react";

function Pinedo() {
	return (
		<div className="my-12 md:px-24 px-12">
			<h1 className="text-center text-4xl md:text-6xl uppercase font-semibold my-4">
				Piazza De Pinedo
			</h1>
			<div className="text-justify">
				<p className="mt-7">
					Piazza De Pinedo a Mondrone è un piccolo gioiello incastonato tra le
					montagne, che incanta per la sua semplicità e autenticità. Intitolata
					al celebre aviatore italiano Francesco De Pinedo, noto per le sue
					imprese di volo negli anni '20 e '30, la piazza, pur di dimensioni
					contenute, offre uno spaccato perfetto della vita di montagna. Al
					centro della piazza si trova una fontana in pietra, simbolo di
					freschezza e punto di ristoro per escursionisti e villeggianti. Le
					case che circondano la piazza, con i loro muri in pietra e le finestre
					adornate di fiori, raccontano storie di tempi passati e preservano un
					fascino rustico e genuino. Passeggiando per Piazza De Pinedo, si
					respira un'atmosfera di tranquillità e calore, che riflette
					l'ospitalità e la semplicità della vita montana.
				</p>
			</div>
			<div className="flex flex-col gap-7 items-center justify-around mt-7">
				<div className="flex flex-col justify-center ">
					<img
						className="h-72"
						src={require("../../../Assets/Paese/foto da aggiungere in piazza de pinedo.jpg")}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}

export default Pinedo;
