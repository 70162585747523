import React from "react";

function Geologia() {
	return (
		<div className="my-12 px-12 md:px-24">
			<h1 className="text-center text-4xl md:text-6xl uppercase font-bold my-4">
				Aspetti naturalistici
			</h1>
			<h2 className="text-center text-3xl uppercase font-semibold">Geologia</h2>
			<div className="text-justify">
				<p className="mt-7">
					La Val d’Ala, che insieme a Val Grande e Val di Viù costituisce le
					Valli di Lanzo, dal punto di vista geologico presenta due grandi zone
					strutturali: la Zona Sesia Lanzo, che occupa una fascia di circa 5
					chilometri di larghezza di direzione NordEst-SudOvest, e la Zona
					Piemontese, che occupa tutto il settore Ovest della valle a cui
					appartengono le vette più importanti (Uia di Bessanese, Uia di
					Mondrone, Torre d'Ovarda, Monte Rosso d'Ma). Nel settore mediano della
					valle nel versante orografico sinistro affiora anche una scaglia delle
					propaggini meridionali del massiccio del Gran Paradiso.
				</p>
				<p className="mt-7">
					Per un inquadramento generale della geologia e dei processi
					geomorfologici che hanno modellato il paesaggio e per
					l’approfondimento su quattro itinerari geologici nelle Valli di Lanzo
					è possibile consultare gli opuscoli pubblicati da ARPA Piemonte:
				</p>
			</div>
			<div className="flex items justify-around">
				<a
					target="_blank"
					rel="noreferrer"
					className="text-blue-500 hover:underline"
					href="http://www.arpa.piemonte.it/pubblicazioni-2/pubblicazioni-anno-2009/pdf-le-valli-di-lanzo-1"
				>
					Le Valli di Lanzo – Vol. 1 Aspetti geologici
				</a>
				<a
					target="_blank"
					rel="noreferrer"
					className="text-blue-500 hover:underline"
					href="http://www.arpa.piemonte.it/pubblicazioni-2/pubblicazioni-anno-2009/pdf-le-valli-di-lanzo-2"
				>
					Le Valli di Lanzo – Vol. 2 Itinerari
				</a>
			</div>
			<p className="mt-4">
				I corsi d’acqua che percorrono le Valli sono denominati Stura di Val
				Grande, Stura di Ala e Stura di Viù. Dalla loro confluenza ha origine la
				Stura di Lanzo.
			</p>
			<p className="mt-4">
				Il Pian della Mussa, ovvero l'ampia conca di origine glaciale situata
				alla testata della valle e dominata dai ghiacciai della Ciamarella, è
				nota per la qualità delle sue acque sorgive che hanno alimentato
				l'acquedotto della città di Torino. L’acquedotto del Pian della Mussa,
				inaugurato il 24 giugno 1922, consentì di portare a Torino l’acqua della
				Valle di Lanzo e ancora oggi, perfettamente funzionante, grazie agli
				interventi manutentivi di SMAT, rifornisce i Comuni di Balme, Ala di
				Stura, Pessinetto, Ceres, Lanzo, Cafasse, Fiano e Robassomero e la
				Centrale SMAT di Venaria Reale.
			</p>
			<p className="mt-4">
				Le rocce della valle sono incise da numerose profonde gole a pareti
				verticali e avvicinate scavate dall'azione erosiva dell'acqua formando
				suggestivi orridi come la gorgia di Mondrone; numerose cascate inoltre
				circondano il Pian della Mussa formando salti notevoli come la cascata
				di Balme.
			</p>
			<p className="mt-4">
				Gli aspetti geologici della valle hanno caratterizzato, oltre l'aspetto
				del paesaggio, anche la storia secolare dell'uomo. Fin dal 1300 piccole
				miniere di minerali di ferro e rame hanno costituito un importante
				elemento dell'economia valligiana, richiamando minatori e imprenditori
				provenienti da altre valli. In Val d’Ala sono note le miniere di talco
				Bellacosta presso Ceres e i giacimenti delle Curbassere sopra Ala di
				Stura. Oggi le miniere sono abbandonate ma l'importanza scientifica dei
				ritrovamenti mineralogici è comprovata da studi e ricerche.
			</p>
			<div className="grid grid-cols-1 items-center justify-items-center mt-7">
				
				<div>
					<img
						className="w-96"
						src={require("../../../Assets/panorama.jpg")}
						alt=""
					/>
					<p>Panorama</p>
				</div>
			</div>
		</div>
	);
}

export default Geologia;
