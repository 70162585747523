import React from "react";
import Ala from "../../Components/Molecules/Modals/Meteo/Ala";
import Balme from "../../Components/Molecules/Modals/Meteo/Balme";

function Meteo() {
	const date = new Date();
	function getnameoftheday(date) {
		if (date === 0) {
			return "Domenica";
		} else if (date === 1) {
			return "Lunedì";
		} else if (date === 2) {
			return "Martedì";
		} else if (date === 3) {
			return "Mercoledì";
		} else if (date === 4) {
			return "Giovedì";
		} else if (date === 5) {
			return "Venerdì";
		} else if (date === 6) {
			return "Sabato";
		}
	}
	function getnameofthemonth(month) {
		if (month === 0) {
			return "Gennaio";
		} else if (month === 1) {
			return "Febbraio";
		} else if (month === 2) {
			return "Marzo";
		} else if (month === 3) {
			return "Aprile";
		} else if (month === 4) {
			return "Maggio";
		} else if (month === 5) {
			return "Giugno";
		} else if (month === 6) {
			return "Luglio";
		} else if (month === 7) {
			return "Agosto";
		} else if (month === 8) {
			return "Settembre";
		} else if (month === 9) {
			return "Ottobre";
		} else if (month === 10) {
			return "Novembre";
		} else if (month === 11) {
			return "Dicembre";
		}
	}
	return (
		<div className="mt-12 grid md:grid-cols-3 grid-cols-1 gap-12 items-start">
			<div className="md:col-span-3 text-center">
				<h1 className="md:text-6xl text-4xl font-semibold uppercase "> il Meteo di oggi</h1>
				<p className="text-xl mt-4">
					{getnameoftheday(date.getDay()) +
						" " +
						date.getDate() +
						" " +
						getnameofthemonth(date.getMonth()) +
						" " +
						date.getFullYear()}
				</p>
			</div>
			<div className="flex space-y-3 flex-col justify-center items-center">
				<Ala />
				<iframe
					title="Ala di Stura"
					className="w-[250px] h-[250px] scale-125"
					src="https://www.3bmeteo.com/moduli_esterni/localita_1_giorno_compatto/94/ffffff/3b82f6/5e5e5e/ffffff/it"
					frameborder="0"
				></iframe>
			</div>
			<div className="flex space-y-3 flex-col justify-center items-center">
				<Balme />
				<iframe
					title="Balme"
					className="w-[250px] h-[250px] scale-125"
					src="https://www.3bmeteo.com/moduli_esterni/localita_1_giorno_compatto/486/ffffff/3b82f6/5e5e5e/ffffff/it"
					frameborder="0"
				></iframe>
			</div>

			<div className="flex flex-col space-y-1">
				<div className="flex flex-col justify-center items-center">
					<button
						onClick={() =>
							window.open("http://cl1978.erre-elle.net:85/", "_blank")
						}
						data-modal-target="extralarge-modal"
						data-modal-toggle="extralarge-modal"
						className="block w-[220px] h-[60px] m-12 mb-0 text-lg text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 text-center "
						type="button"
					>
						Meteo Barbagian
					</button>
					<a
						target="_blank"
						rel="noreferrer"
						className="hover:underline"
						href="https://thingspeak.com/channels/2104356"
					>
						Diagrammi meteo Barbagian
					</a>
				</div>
				<div className="flex flex-col px-12 space-y-1">
				
					<h2 className="text-2xl font-medium">Altro</h2>
					<a
						target="_blank"
						rel="noreferrer"
						className="hover:underline"
						href="https://www.arpa.piemonte.it/bollettini/bollettino_allerta.pdf"
					>
						Bollettino allerta metereologica
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						className="hover:underline"
						href="https://www.arpa.piemonte.it/export/bollettini/bollettino_meteotestuale.pdf"
					>
						Ultimo bollettino meteo
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						className="hover:underline"
						href="https://www.meteo3r.it/app/public/"
					>
						Dati metereologici in tempo reale
					</a>
					<div className="h-[1px] bg-slate-700 w-96"></div>
					<a
						target="_blank"
						rel="noreferrer"
						className="hover:underline"
						href="https://webgis.arpa.piemonte.it/bancadatimeteo/php/meteo.php?CODTOT=001003900"
					>
						Stazione pluviometrica Ala di Stura
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						className="hover:underline"
						href="https://webgis.arpa.piemonte.it/bancadatimeteo/php/meteo.php?CODTOT=001019901"
					>
						Stazione pluviometrica Balme
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						className="hover:underline"
						href="https://webgis.arpa.piemonte.it/bancadatimeteo/php/meteo.php?CODTOT=001118900"
					>
						Stazione pluviometrica Groscavallo
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						className="hover:underline"
						href="https://webgis.arpa.piemonte.it/bancadatimeteo/php/meteo.php?CODTOT=001272907"
					>
						Stazione pluviometrica Torino (Giardini Reali)
					</a>
					<div className="h-[1px] bg-slate-700 w-96"></div>
					<a
						target="_blank"
						rel="noreferrer"
						className="hover:underline"
						href="https://webgis.arpa.piemonte.it/bancadatimeteo/php/meteo.php?CODTOT=001128901"
					>
						Livello idrometrico Stura di Lanzo (Lanzo Torinese)
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						className="hover:underline"
						href="https://webgis.arpa.piemonte.it/bancadatimeteo/php/meteo.php?CODTOT=001272703"
					>
						Livello idrometrico Po (Torino, Murazzi)
					</a>
			</div>
			</div>
			<div className="px-2 flex flex-col md:flex-row md:space-x-5 space-y-5 min-h-[70vh] items-center overflow-x-hidden justify-center w-full md:col-span-3">
				<a
					target="_blank"
					rel="noreferrer"
					href="https://www.meteox.com/images.aspx?jaar=-3&voor=&soort=loop3uur&c=&n"
				>
					<img
						src="http://www.meteox.com/images.aspx?jaar=-3&voor=&soort=loop3uur&c=&n"
						title="Meteo Map"
						className="w-full aspect-square"
					></img>
				</a>
				<a
					target="_blank"
					rel="noreferrer"
					href="https://www.meteo60.org/radars/animation-radar-france.gif"
				>
					<img
						src="https://www.meteo60.org/radars/animation-radar-france.gif"
						title="Meteo Map"
						className="w-full aspect-square"
					></img>
				</a>
				<a
					target="_blank"
					rel="norefferrer"
					href="https://sc05.arpa.piemonte.it/previ/last_movie_compa.gif"
				>
					<img
						src="https://sc05.arpa.piemonte.it/previ/last_movie_compa.gif"
						title="Meteo Map"
						className="w-full aspect-square"
					></img>
				</a>
			</div>
		</div>
	);
}

export default Meteo;
