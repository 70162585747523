import React from "react";

function Gorgia() {
	return (
		<div className="flex flex-col justify-center my-5">
			<h1 className="place-self-center text-center uppercase md:text-6xl text-4xl font-semibold my-4">
				La gorgia di mondrone{" "}
			</h1>
			<div className="text-justify px-12">
				<p className="my-2">
					La Gorgia di Mondrone è una bellissima cascata facilmente
					raggiungibile per un’escursione davvero suggestiva.
				</p>
				<p className="my-2">
					Il modo più semplice e veloce per arrivare alla Gorgia è partire dal
					comodo parcheggio di fronte alla Chiesa di Mondrone. Qui, una volta
					lasciata la macchina, si scende per qualche metro lungo la strada fino
					ad incontrare sulla destra un cartello che indica il sentiero da
					percorrere. Si inizia a scendere costeggiando le case fino ad entrare
					in un ampio prato, si supera un enorme masso e si comincia a sentire
					il rumore della Stura in lontananza. Quando si arriva ad un bivio
					mantenendo la sinistra si percorre un ripido sentiero in discesa e in
					poco meno di tre minuti si arriva al ponte della Gorgia di Mondrone.
				</p>
				<p className="my-2">
					Dal ponte, a circa 60 mt di altezza, lo scenario è imponente: un salto
					di acque impetuose della Stura stretto in una gola rocciosa e sul
					fondo, a vertiginosa distanza, l’irruenza delle acque si stempera in
					un verde bacino dal quale poi il torrente riprende la sua corsa.
				</p>
			</div>
			<div className="grid md:grid-cols-2 gap-6 px-2 items-center justify-items-center mt-11">
				<div className="flex flex-col justify-center">
					<img
						className="h-80"
						src={require("../Assets/Paese/cascata.jpg")}
						alt=""
					/>
					<p className="hidden">Foliage autunnale</p>
				</div>
				<div className="flex flex-col justify-center">
					<img
						className="h-80"
						src={require("../Assets/Paese/inizio sentiero.jpg")}
						alt=""
					/>
					<p className="hidden">Campanula Elatines</p>
				</div>
			</div>
		</div>
	);
}

export default Gorgia;
