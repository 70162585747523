import React from "react";

function Tesseramento() {
	return (
		<div className="flex flex-col items-center justify-center">
			<h1 className="text-center md:text-6xl text-4xl uppercase font-semibold my-4">
				TESSERAMENTO
			</h1>
			<div className="md:px-24 px-12">
				<p className=" text-justify">
					Per diventare soci del Circolo Mondronese è sufficiente versare una
					quota associativa annuale. Si aderisce come nucleo familiare e
					le quote vengono raccolte dal Segretario Tesoriere in presenza,
					generalmente durante gli eventi della stagione.
				</p>
				<p className="mt-7">
					Per contatti e richieste specifiche sul tesseramento scrivere a
					
					<a
						className="hover:underline text-blue-500 cursor-pointer"
						href="mailto:direttivo@mondrone.it"
					>
						{" "}
						direttivo@mondrone.it {" "}
					</a>
					oppure a
					<a
						className="hover:underline text-blue-500 cursor-pointer"
						href="mailto:mondrone@mondrone.it"
					>
						{" "}
						mondrone@mondrone.it
					</a>
				</p>
				<p className="mt-7  text-justify">
					La quota sociale dà la possibilità di usufruire di sconti presso
					esercizi commerciali convenzionati presenti in valle e non, di
					ottenere agevolazioni sui costi di partecipazione di alcune
					manifestazioni estive e di partecipare all’assegnazione per estrazione
					del numero di tessera di premi annuali.
				</p>
			</div>
		</div>
	);
}

export default Tesseramento;
