import React, { useEffect, useState } from "react";
import Megatoggle from "../../Components/Molecules/Megatoggle/Megatoggle";
import Megatoggle2 from "../../Components/Molecules/Megatoggle/Megatoggle2";
import Title from "../../Components/Molecules/Title/Title";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "../../Components/Molecules/Card/Card";
import MegaTgglWebcam from "../../Components/Molecules/Megatoggle/MegaTgglWebcam";
import Tel from "../../Components/Atoms/Icons/Tel";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import Fissata from "../../Components/Molecules/Card/Fissata";
import Numeri from "../../Components/Molecules/Card/Numeri";

function Home() {
	// CustomPrevArrow.js
	const [fissata, setFissata] = useState([]);
	const [Data, setData] = useState([]);

	const handleLoad = async () => {
		const querySnapshot = await getDocs(collection(db, "news"));
		const docs = querySnapshot.docs;
		const dati = docs.map((doc) => doc.data());
		const sorted = dati.sort((a, b) => b.date - a.date);
		setFissata(
			dati.filter((item) => item.fissato).sort((a, b) => b.fissato - a.fissato)
		);
		setData(sorted);
	};

	useEffect(() => {
		handleLoad();
	}, []);

	const CustomPrevArrow = (props) => {
		const { className, onClick } = props;
		return (
			<div className={className + "relative "} onClick={onClick}>
				<svg
					className="absolute left-2 z-20 top-1/2 -translate-y-[95%] rotate-180 cursor-pointer hover:scale-90 transition-all duration-300"
					height="40px"
					width="40px"
					version="1.1"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 512 512"
					xmlSpace="preserve"
				>
					<g id="SVGRepo_bgCarrier" strokeWidth="0" />
					<g
						id="SVGRepo_tracerCarrier"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<g id="SVGRepo_iconCarrier">
						<g>
							<g>
								<path
									d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.616,256-256S397.385,0,256,0z M346.899,280.959 l-85.594,85.594c-13.783,13.784-36.132,13.784-49.917,0c-13.784-13.784-13.784-36.133,0-49.917L272.023,256l-60.635-60.635 c-13.784-13.784-13.784-36.133,0-49.917s36.134-13.784,49.917,0l85.594,85.594C360.683,244.825,360.683,267.175,346.899,280.959z"
									fill="rgb(29 78 216)"
								/>
							</g>
						</g>
					</g>
				</svg>
			</div>
		);
	};

	// CustomNextArrow.js
	const CustomNextArrow = (props) => {
		const { className, onClick } = props;
		return (
			<div className={className + "relative"} onClick={onClick}>
				<svg
					className="absolute right-2 -translate-y-[95%] top-1/2 cursor-pointer hover:scale-90 transition-all duration-300"
					height="40px"
					width="40px"
					version="1.1"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 512 512"
					xmlSpace="preserve"
				>
					<g id="SVGRepo_bgCarrier" strokeWidth="0" />
					<g
						id="SVGRepo_tracerCarrier"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<g id="SVGRepo_iconCarrier">
						<g>
							<g>
								<path
									fill="rgb(29 78 216)"
									d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.616,256-256S397.385,0,256,0z M346.899,280.959 l-85.594,85.594c-13.783,13.784-36.132,13.784-49.917,0c-13.784-13.784-13.784-36.133,0-49.917L272.023,256l-60.635-60.635 c-13.784-13.784-13.784-36.133,0-49.917s36.134-13.784,49.917,0l85.594,85.594C360.683,244.825,360.683,267.175,346.899,280.959z"
								></path>
							</g>
						</g>
					</g>
				</svg>
			</div>
		);
	};
	var settings = {
		dots: true,
		infinite: true,
		speed: 2500,
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: true,
		pauseOnHover: true,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
	};

	var settings_news = {
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: false,
		arrows: true,
		pauseOnHover: true,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
		slidesToShow: 2,
		slidesToScroll: 2,
	};

	var foto = [
		"scrittaMONDRONE.jpg",
		"primavera.jpg",
		"montagna.jpeg",
		"6.jpg",
		"areabimbi.jpg",
	];

	return (
		<div className="w-full relative md:my-7">
			<Slider {...settings}>
				<Title />
				{foto.map((foto, index) => (
					<img
						src={require("../../Assets/foto/slider/" + foto)}
						key={index}
						className="w-full aspect-[2.78/1]  object-cover"
						alt=""
					/>
				))}
			</Slider>
			<div className="md:px-12 grid md:grid-cols-2 mt-24 place-items-center gap-6">
				<div className="grid grid-cols-1 gap-8">
					<MegaTgglWebcam />
					<Megatoggle />
					<Megatoggle2 />
				</div>
				<div className="">
					<p className="text-6xl text-center mb-7 font-semibold cursor-pointer">
						<a className=" hover:underline " href="/news">
							NEWS
						</a>
					</p>
					{fissata.length > 0 && (
						<div className="">
							<Fissata data={fissata[0]} id={fissata[0].id} />
						</div>
					)}
					<div className="md:w-[800px] w-screen grid grid-cols-1 gap-6">
						<Slider {...settings_news}>
							{Data.map((item, index) => {
								return <Card data={item} id={item.id} />;
							})}
						</Slider>
					</div>
				</div>
				<div className="border border-gray-300 rounded-xl shadow-xl h-[400px] md:w-[500px] justify-between p-4 gap-4">
					<h3 className="text-2xl mb-3 col-span-2 font-semibold">
						Dov'è Mondrone?
					</h3>
					<iframe
						title="p"
						className="w-full h-[88%] rounded-lg"
						src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d23819.941503018013!2d7.263520819447173!3d45.311738083071596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sit!2sit!4v1686947259839!5m2!1sit!2sit"
						allowfullscreen=""
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
					></iframe>
				</div>
				<div className="px-4">
					<Numeri />
				</div>
			</div>
		</div>
	);
}

export default Home;
/*
					Mondrone è un piccolo borgo che si ritiene esistesse già intorno
					all'anno 1000. Originariamente chiamato Mondrone, Mondreono,
					Mondarono, Montdreon, forse dal monte che lo sovrasta, che anticamente
					era chiamato Mons Dreonis, divenne un comune indipendente nella prima
					metà del 1300, ma fu successivamente incorporato nuovamente nel comune
					di Ala nel 1928. 
					


					Il villaggio è noto per la sua posizione pittoresca,
					immerso nei prati e dominato dalla maestosa montagna Uja. Offre varie
					attrazioni, come la splendida cascata chiamata "Gorgia" e la sorgente
					incontaminata di Pianard. Il villaggio vanta anche edifici ben
					conservati, stradine strette e dipinti storici, tra cui affreschi
					nella chiesa eseguiti da Luigi Peracchione-Lor.
					*/
