import React from "react";

function Uja() {
	return (
		<div className="my-12 md:px-24 px-6">
			<h1 className="text-center text-4xl md:text-6xl uppercase font-semibold my-4">
				l'Uja di Mondrone
			</h1>
			<div className="text-justify">
				<p className="mt-7">
					L' <strong> Uja di Mondrone</strong> è una maestosa cima situata nelle
					Alpi Graie, in Piemonte. Questa vetta, che raggiunge un'altitudine di
					2.964 metri, si erge maestosamente nella <strong>Val d'Ala</strong>,
					una valle laterale della più nota Val di Lanzo, nella provincia di
					Torino.
				</p>
				<p className="mt-7">
					Il nome "Uja" deriva dal termine dialettale piemontese che significa
					"spunzone" o "guglia", riflettendo la caratteristica forma appuntita
					della montagna. L'Uja di Mondrone è un punto di riferimento per gli
					escursionisti e gli alpinisti della regione, grazie alla sua
					accessibilità e alle viste spettacolari che offre.
				</p>
				<p className="mt-7">
					La <strong>via di accesso</strong> più comune per raggiungere la vetta
					inizia dal piccolo borgo di Mondrone, suggestivo villaggio montano che
					conserva ancora le caratteristiche tradizionali delle comunità alpine.
					Da qui, il <strong>sentiero</strong> si snoda attraverso boschi di
					conifere e pascoli alpini, salendo gradualmente fino a raggiungere le
					zone più rocciose e impegnative dell'ascensione.
				</p>
				<p className="mt-7">
					La <strong>salita</strong>, pur non essendo particolarmente tecnica,
					richiede comunque un buon livello di preparazione fisica e una certa
					esperienza in montagna, soprattutto nei tratti finali dove il terreno
					diventa più ripido e accidentato. Durante l'
					<strong>escursione</strong>, si possono ammirare bellissimi paesaggi e
					una ricca varietà di flora e fauna alpina, rendendo l'esperienza ancor
					più affascinante.
				</p>
				<p className="mt-7">
					Una volta in cima, il <strong>panorama</strong> è{" "}
					<strong>straordinario</strong>: nelle giornate limpide, la vista
					spazia a 360 gradi, abbracciando le cime circostanti delle Alpi Graie,
					le lontane Alpi Marittime e, a sud, la pianura piemontese. La
					sensazione di isolamento e la tranquillità della cima offrono
					un'opportunità unica per riflettere e godere della bellezza della
					natura incontaminata.
				</p>
				<p className="mt-7">
					L'Uja di Mondrone è anche un punto di osservazione privilegiato per
					apprezzare la geologia delle Alpi Graie, con le sue formazioni
					rocciose e le tracce lasciate dai ghiacciai che un tempo modellavano
					questi paesaggi. Per gli appassionati di fotografia, la vetta
					rappresenta un luogo ideale per catturare immagini spettacolari
					dell'ambiente alpino.
				</p>
				<p className="mt-7">
					L'Uja di Mondrone è una destinazione imperdibile per chi ama la
					montagna e desidera vivere un'avventura tra natura e panorami
					indimenticabili. La sua ascesa, benché impegnativa, ripaga ogni sforzo
					con una vista impareggiabile e la soddisfazione di aver raggiunto una
					delle cime più affascinanti delle Alpi piemontesi.
				</p>
			</div>
			<div className="flex flex-col md:flex-row items-center justify-around gap-7 mt-7">
				<div className="flex flex-col justify-center">
					<img
						className="max-w-full h-auto"
						src={require("../../../Assets/Paese/uja.jpg")}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}

export default Uja;
