import React from "react";

function Footer() {
	return (
		<div>
			<footer className="bg-white rounded-lg shadow m-4 md:m-0">
				<div className="w-full max-w-screen-2xl mx-auto p-4 md:px-12 md:py-8">
					<div className="sm:flex sm:items-center sm:justify-between">
						<a href="/" className="flex items-center">
							<img
								src={require("../../../Assets/stemma1.jpg")}
								className="h-16 md:mr-3"
								alt="Stemma"
							/>
							<span className="self-center text-5xl font-semibold whitespace-nowrap ">
								Mondrone
							</span>
							<img
								src={require("../../../Assets/stemma2.jpg")}
								className="h-16 md:ml-3"
								alt="Stemma"
							/>
						</a>

						<ul className="flex flex-wrap items-center mb-6 font-medium text-gray-500 sm:mb-0">
							<li>
								<div className="flex text-md flex-col">
							<li>
								<p className="mr-4 hover:underline md:mr-6">Contatti: </p>
							</li>
									<a
										href="mailto:mondrone@mondrone.it"
										className="mr-4 hover:underline md:mr-6"
									>
										Mail: mondrone@mondrone.it
									</a>
									<a href="mailto:direttivo@mondrone.it" className="mr-4 hover:underline md:mr-6">
										Direttivo: direttivo@mondrone.it
									</a>
									<a href="mailto:direttivo@pec.mondrone.it" className="mr-4 hover:underline md:mr-6">
										PEC: mondrone@pec.mondrone.it
									</a>
								</div>
							</li>
							<li>
								<a href="/privacy" className="mr-4 text-sm hover:underline md:mr-6">
									Privacy Policy
								</a>
							</li>
							<li>
								<a href="/admin" className="hover:underline text-sm">
									Admin
								</a>
							</li>
						</ul>
					</div>
					<hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
					<span className="block text-sm text-gray-500 sm:text-center ">
						© 2023{" "}
						<a
							href="https://webinal.it/"
							className="hover:underline"
							target="_blank"
							rel="noreferrer"
						>
							Webinal™
						</a>
						<p className="text-right"> Sito creato il 23 ottobre 2000</p>
						<p className="text-right"> Sito rinnovato il 5 agosto	 2023</p>
					</span>
				</div>
			</footer>
		</div>
	);
}

export default Footer;
