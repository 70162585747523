import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Navbar from "./Components/Molecules/Navabr/Navbar";
import Footer from "./Components/Molecules/Footer/Footer";
import News from "./Pages/News/News";
import Circolo from "./Pages/Circolo/Circolo";
import Chisiamo from "./Pages/Circolo/Chisiamo";
import Balme from "./Pages/Webcam/Balme";
import Cirie from "./Pages/Webcam/Cirie";
import Gastaldi from "./Pages/Webcam/Gastaldi";
import Webcam from "./Pages/Webcam/Webcam";
import Meteo from "./Pages/Meteo/Meteo";
import Admin from "./Pages/Admin/Admin";
import Foto from "./Pages/Admin/Foto";
import Verbali from "./Pages/Admin/Verbali";
import Al_Ba from "./Pages/Escursioni/Al_Ba";
import Panchina from "./Pages/Escursioni/Panchina";
import CaricaNews from "./Pages/Admin/AggiungiNews";
import ModNews from "./Pages/Admin/News";
import Articolo from "./Pages/Articolo/Articolo";
import ArticlePage from "./Pages/Articolo/Articolo";
import WebcamBTN from "./Pages/Webcam/WebcamBTN";
import Storia from "./Pages/Paese/Storia";
import Bidone from "./Pages/Paese/Tradizioni/Bidone";
import Fauna from "./Pages/Paese/Natura/Fauna";
import Flora from "./Pages/Paese/Natura/Flora";
import Geologia from "./Pages/Paese/Natura/Geologia";
import Arrivare from "./Pages/Paese/Arrivare";
import Bilanci from "./Pages/Admin/Bilanci";
import Giornalino from "./Pages/Admin/Giornalino";
import Tesseramento from "./Pages/Circolo/Tesseramento";
import Convenzioni from "./Pages/Circolo/Convenzioni";
import Piazza from "./Pages/Paese/Luoghi/Piazza";
import WorkInProgress from "./Pages/WorkInProgress";
import Gite from "./Pages/Gite";
import Biblioteca from "./Pages/Paese/Spazi/Biblioteca";
import Gorgia from "./Pages/Gorgia";
import Pianard from "./Pages/Paese/Luoghi/Pianard";
import Costumi from "./Pages/Paese/Tradizioni/Costumi";
import Soci from "./Pages/Soci/Soci";
import Privacy from "./Pages/Privacy/Privacy";
import Galleria from "./Pages/Galleria/Galleria";
import NewNavbar from "./Components/Molecules/Navabr/NewNavbar";
import Archivio from "./Pages/Admin/Archivio";
import AreaSportiva from "./Pages/Paese/Spazi/AreaSportiva";
import Uja from "./Pages/Paese/Luoghi/Uja";
import Scarpalonga from "./Pages/Paese/Tradizioni/Scarpalonga";
import GiochiBimbi from "./Pages/Paese/Spazi/GiochiBimbi";
import Liberi from "./Pages/Circolo/Liberi";
import Pinedo from "./Pages/Paese/Luoghi/Pinedo";
import PanchinaGigante from "./Pages/PanchinaGigante";
import Meridiane from "./Pages/Meridiane";
import MontagnaInCampo from "./Pages/Circolo/MontagnaInCampo";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<NewNavbar />

				<Routes>
					<Route path="/" element={<Home />} />

					<Route path="/news" element={<News />} />
					<Route path="/news/:id" element={<ArticlePage />} />

					<Route path="/circolo" element={<Circolo />} />
					<Route path="/circolo/chisiamo" element={<Chisiamo />} />
					<Route path="/circolo/tesseramento" element={<Tesseramento />} />
					<Route path="/circolo/convenzioni" element={<Convenzioni />} />
					<Route path="/circolo/lib(e)ri" element={<Liberi />} />
					<Route path="/circolo/Scarpalonga" element={<Scarpalonga />} />
					<Route path="/circolo/montagna-in-campo" element={<MontagnaInCampo />} />

					<Route path="/balme" element={<Balme />} />
					<Route path="/gite" element={<Gite />} />
					<Route path="/gorgia" element={<Gorgia />} />

					<Route path="/cirie" element={<Cirie />} />

					<Route path="/gastaldi" element={<Gastaldi />} />

					<Route path="/webcam/mondrone" element={<Webcam />} />
					<Route path="/webcam/altripaesi" element={<WebcamBTN />} />

					<Route path="/meteo" element={<Meteo />} />

					<Route path="/foto" element={<Galleria />} />

					<Route path="/arrivareamondrone" element={<Arrivare />} />
					<Route path="/bidone" element={<Bidone />} />
					<Route path="/tradizioni/costumi" element={<Costumi />} />
					<Route path="/natura/flora" element={<Flora />} />
					<Route path="/natura/fauna" element={<Fauna />} />
					<Route path="/natura/geologia" element={<Geologia />} />
					<Route path="/luoghi/piazza" element={<Piazza />} />
					<Route path="/luoghi/pinedo" element={<Pinedo />} />
					<Route path="/luoghi/pianard" element={<Pianard />} />
					<Route path="/Uja" element={<Uja />} />
					<Route path="/spaziaggregativi/biblioteca" element={<Biblioteca />} />
					<Route path="/spaziaggregativi/areasportiva" element={<AreaSportiva />} />
					<Route path="/spaziaggregativi/giochibimbi" element={<GiochiBimbi />} />

					<Route path="/al_ba" element={<Al_Ba />} />
					<Route path="/escursionepanchina" element={<Panchina />} />
					<Route path="/storia" element={<Storia />} />
					<Route path="/panchina" element={<PanchinaGigante />} />
					<Route path="//meridianeeaffreschi" element={<Meridiane />} />

					<Route path="/admin" element={<Admin />} />
					<Route path="/caricaarchivio" element={<Archivio />} />
					<Route path="/caricafoto" element={<Foto />} />
					<Route path="/caricaverbali" element={<Verbali />} />
					<Route path="/caricabilanci" element={<Bilanci />} />
					<Route path="/caricagiornalino" element={<Giornalino />} />
					<Route path="/modnews/:id" element={<CaricaNews nuovo={false} />} />
					<Route path="/aggiunginews" element={<CaricaNews nuovo={true} />} />
					<Route path="/modnews" element={<ModNews />} />
					<Route path="/soci" element={<Soci />} />
					<Route path="/privacy" element={<Privacy />} />

					<Route path="/costruzione" element={<WorkInProgress />} />
					<Route path="/*" element={<WorkInProgress />} default={true} />
				</Routes>

				<Footer />
			</BrowserRouter>
		</div>
	);
}

export default App;
