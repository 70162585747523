import React, { useEffect } from "react";
import Cookies from "universal-cookie";

function IsLogged() {
	const cookie = new Cookies();

	if (cookie.get("token") === undefined) {
		if (window.location.pathname !== "/admin") {
			window.location.href = "/admin";
		}
		return false;
	} else if (cookie.get("token") === "112358132134") {
		return true;
	}
}

export default IsLogged;
