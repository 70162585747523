import React from "react";

function Bidone() {
	return (
		<div className="grid grid-cols-1 md:grid-cols-2 place-items-center">
			<div className="px-12">
				<h1 className="text-4xl md:text-6xl font-semibold text-center mt-2 mb-6 uppercase">Bidone</h1>
				<div className="text-justify">
					<p className="my-2">
						Il gioco del “bidone” è giocato sul campo di cemento, con le regole
						del calcetto, ma con l’obiettivo di segnare colpendo un bidone di
						media grandezza, difeso da ciascuna squadra. Il “bidone” è un gioco
						molto difficile che richiede ed affina la tecnica, poiché il
						giocatore si deve destreggiare in spazi ristretti ed ha come
						obiettivo un oggetto di solito ben difeso.
					</p>

					<p className="my-2">
						La regola di base è che per segnare bisogna colpire un bidone di
						metallo, di media grandezza più o meno, alto circa 50 - 60 cm, posto
						esattamente al centro del lato corto del campo, dove si dovrebbe
						trovare la porta da calcio.
					</p>

					<p className="my-2">
						Le regole sono più o meno un misto di calcio e calcetto, con qualche
						pizzico di mondronesità: la partita si gioca quattro contro quattro
						(ma non mancano i tre contro tre o cinque contro cinque), senza un
						tempo prefissato, se non si tratta di tornei o incontri ufficiali.
					</p>
					<p className="my-2">
						Ogni squadra ha un giocatore che è il bidonista, cioè colui che è
						vicino al bidone e ha il compito di impedire che la squadra
						avversaria segni un "bidone", ossia un goal. La particolarità è che
						il bidonista, al pari di tutti gli altri giocatori non può mai
						toccare la palla con le mani; si usano sempre solo i piedi.
					</p>
					<p className="my-2">
						Il campo di gioco è un campo di cemento, della misura di un campo di
						pallavolo, delimitato sui due lati corti da una rete e sui due lati
						lunghi dai "cassoni" di pietra e da una recinzione che separa dal
						campo di calcio in erba.
					</p>

					<p className="my-2">
						La palla è in fallo di fondo quando supera interamente il bordo di
						cemento lungo il lato corto del campo; è in fallo laterale se supera
						la recinzione che delimita il lato lungo del campo, ovvero se supera
						le gradinate di pietra, senza tornare in campo da sola. Altrimenti è
						sempre in gioco, anche se rimbalza e torna in campo.
					</p>

					<p className="my-2">
						Il calcio di rinvio è battuto dal portiere o da qualsiasi altro
						giocatore con i piedi dalla propria area. Il fallo laterale viene
						battuto con i piedi in corrispondenza del punto in cui la palla è
						uscita, mentre il calcio d'angolo viene battuto in corrispondenza
						della linea mediana del campo.
					</p>

					<p className="my-2">
						L'area di rigore è costituita dal rettangolo di campo delimitato
						dalla linea di fondo, dalla linea del campo di pallavolo e dalle
						linee di fallo laterale. Il calcio di rigore viene battuto da
						centrocampo verso il bidone senza difesa.
					</p>
					<p className="my-2">
						Sono validi anche i "bidoni" realizzati tirando dalla propria metà
						campo.
					</p>
				</div>
			</div>
			<div className="px-6 grid grid-cols-1 justify-items-center gap-20">
				<img className='w-[600px] rounded-lg' src={require('../../../Assets/BIDONEcampetto.jpg')} alt="" />
				<img src={require('../../../Assets/bidon.jpg')} alt="" />
			</div>
		</div>
	);
}

export default Bidone;
