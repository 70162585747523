import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import { IoCloseOutline } from "react-icons/io5";
import { GoArrowSwitch } from "react-icons/go";
import {
    collection,
    addDoc,
    getDocs,
    deleteDoc,
    doc,
} from "firebase/firestore";
import { db } from "../../../firebase";

function BasicFAQ() {
    const [isOpen, setIsOpen] = useState(false);
    const [indice, setIndice] = useState(-1);
    const [currentPhotos, setCurrentPhotos] = useState([]);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [archivio, setArchivio] = useState([]);

    const [eventi, setEventi] = useState([]);

    const handleLoad = async () => {
        const querySnapshot = await getDocs(collection(db, "archivio"));
        const docs = querySnapshot.docs;
        let Data = docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        const querySnapshot2 = await getDocs(collection(db, "eventi"));
        const docs2 = querySnapshot2.docs;
        const EVENTI = docs2.map((doc) => ({ id: doc.id, ...doc.data() }));
        console.log(EVENTI);
        const listaEventi = EVENTI.map((evento) => evento.id);
        console.log(listaEventi);

        Data = Data.filter((foto) => listaEventi.includes(foto.type));

        const getName = (id) => {
            console.log(id);
            return EVENTI.filter((evento) => evento.id === id)[0] || {};
        };

        Data.map((foto) => {
            return (foto.type = getName(foto.type).title);
        });

        setArchivio(Data);
        console.log("archivio", Data);
    };

    useEffect(() => {
        handleLoad();
    }, []);

    const [sortOrder, setSortOrder] = useState("asc"); // 'asc' for ascending, 'desc' for descending
    const groupedArchivio = archivio.reduce((acc, foto) => {
        if (!acc[foto.type]) {
            acc[foto.type] = [];
        }
        acc[foto.type].push(foto);
        return acc;
    }, {});
    return (
        <div className="px-4 py-12">
            <div className="w-full px-10 mx-auto">
                <h3 className="text-center text-5xl font-semibold mb-12">
                    Archivio fotografico eventi
                </h3>
                <div>
                    <button
                        className="flex flex-col items-center justify-center gap-2 w-full h-full"
                        onClick={() => setSortOrder(sortOrder === "asc" ? "dec": "asc")}
                    >
                        <p>
                            Ordina per data: dal più{" "}
                            {sortOrder === "asc" ? "vecchio" : "recente"}
                        </p>
                        <GoArrowSwitch className="text-2xl" />
                    </button>
                    {Object.entries(groupedArchivio).map(
                        ([type, fotos], index) => (
                            <Question eventi={eventi} title={type} key={index}>
                                <div className="grid grid-cols-3 md:grid-cols-6 place-items-center gap-1 md:gap-5 px-5">
                                    {fotos
                                        .sort((a, b) => {
                                            const dataA = new Date(a.date).getTime();
                                            const dataB = new Date(b.date).getTime();
                                            if (sortOrder === "asc") {
                                                return dataA - dataB;
                                            } else {
                                                return dataB - dataA;
                                            }
                                        })
                                        .map((foto, fotoIndex) => (
                                            <div key={fotoIndex}>
                                                <img
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        setCurrentPhotos(fotos);
                                                        setCurrentPhotoIndex(
                                                            fotoIndex
                                                        );
                                                    }}
                                                    src={foto.url}
                                                    alt={foto.type}
                                                    className="w-full h-auto"
                                                />
                                                <p className="text-sm text-slate-600">
                                                    {foto.date}
                                                </p>
                                                <SpringModal
                                                    isOpen={isOpen}
                                                    setIsOpen={setIsOpen}
                                                    photos={currentPhotos}
                                                    photoIndex={
                                                        currentPhotoIndex
                                                    }
                                                    setPhotoIndex={
                                                        setCurrentPhotoIndex
                                                    }
                                                />
                                            </div>
                                        ))}
                                </div>
                            </Question>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

const Question = ({ eventi, title, children, defaultOpen = false }) => {
    const [open, setOpen] = useState(defaultOpen);

    return (
        <motion.div
            animate={open ? "open" : "closed"}
            className="border-b-[1px] border-b-slate-300"
        >
            <button
                onClick={() => setOpen((pv) => !pv)}
                className="py-6 w-full flex items-center justify-between gap-4"
            >
                <motion.span
                    variants={{
                        open: {
                            color: "rgba(3, 6, 23, 0)",
                        },
                        closed: {
                            color: "rgba(3, 6, 23, 1)",
                        },
                    }}
                    className="text-lg font-semibold uppercase text-left bg-blue-500 bg-clip-text"
                >
                    {title}
                </motion.span>
                <motion.span
                    variants={{
                        open: {
                            rotate: "180deg",
                            color: "rgb(59 130 246)",
                        },
                        closed: {
                            rotate: "0deg",
                            color: "#030617",
                        },
                    }}
                >
                    <FiChevronDown className="text-2xl" />
                </motion.span>
            </button>

            <motion.div
                initial={false}
                animate={{
                    height: open ? "fit-content" : "0px",
                    marginBottom: open ? "24px" : "0px",
                }}
                className="overflow-hidden text-slate-600"
            >
                {children}
            </motion.div>
        </motion.div>
    );
};

export default BasicFAQ;

const SpringModal = ({
    isOpen,
    setIsOpen,
    photos,
    photoIndex,
    setPhotoIndex,
}) => {
    const slide = (direction) => {
        let newIndex = direction === "next" ? photoIndex + 1 : photoIndex - 1;
        if (newIndex < 0) {
            newIndex = photos.length - 1;
        } else if (newIndex === photos.length) {
            newIndex = 0;
        }
        setPhotoIndex(newIndex);
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer select-none"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="flex flex-col items-center justify-center bg-white p-1 md:p-6 rounded-lg w-full md:h-[80vh] h-[60vh] shadow-xl cursor-default relative overflow-hidden"
                    >
                        <IoCloseOutline
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            className="cursor-pointer place-self-end absolute top-[2%] text-5xl text-white bg-[#1d4ed8] rounded-full hover:scale-90 transition-all duration-300"
                        />{" "}
                        <div className="h-full">
                            <CustomPrevArrow click={() => slide("prev")} />
                            <img
                                className="md:max-w-6xl  h-[100%] object-contain"
                                src={photos[photoIndex].url}
                                alt=""
                            />
                            <p className="text-sm text-slate-600 text-center">
                                {photos[photoIndex].data}
                            </p>
                            <CustomNextArrow click={() => slide("next")} />
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
const CustomPrevArrow = ({ click }) => {
    return (
        <svg
            onClick={() => click()}
            className="absolute left-1/2 -translate-x-[200%] z-20 bottom-0 -translate-y-[95%] rotate-180 cursor-pointer md:hover:scale-90 transition-all duration-300"
            height="40px"
            width="40px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path
                            d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.616,256-256S397.385,0,256,0z M346.899,280.959 l-85.594,85.594c-13.783,13.784-36.132,13.784-49.917,0c-13.784-13.784-13.784-36.133,0-49.917L272.023,256l-60.635-60.635 c-13.784-13.784-13.784-36.133,0-49.917s36.134-13.784,49.917,0l85.594,85.594C360.683,244.825,360.683,267.175,346.899,280.959z"
                            fill="rgb(29 78 216)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
const CustomNextArrow = ({ click }) => {
    return (
        <svg
            onClick={() => click()}
            className="absolute right-1/2 translate-x-[200%] -translate-y-[95%] bottom-0 cursor-pointer md:hover:scale-90 transition-all duration-300"
            height="40px"
            width="40px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path
                            fill="rgb(29 78 216)"
                            d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.616,256-256S397.385,0,256,0z M346.899,280.959 l-85.594,85.594c-13.783,13.784-36.132,13.784-49.917,0c-13.784-13.784-13.784-36.133,0-49.917L272.023,256l-60.635-60.635 c-13.784-13.784-13.784-36.133,0-49.917s36.134-13.784,49.917,0l85.594,85.594C360.683,244.825,360.683,267.175,346.899,280.959z"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};
