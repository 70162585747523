import React, { useState } from "react";
import NormalBtn from "../../Atoms/Buttons/NormalBtn";

function Megatoggle() {
	const [first, setFirst] = useState(false);

	return (
		<div className="md:w-[600px] w-[370px] md:h-44 h-32 p-3 items-center shadow-xl rounded-full flex flex-row-reverse bg-[url(./Assets/foto/circolo.jpg)] bg-cover ">
			<div className={`rounded-full h-full w-full  relative`}>
				<div
					className={`${
						first ? "opacity-90" : "opacity-0"
					} rounded-full h-full w-full object-cover transition-opacity  duration-500 top-0 absolute bg-white `}
				></div>
				<div
					onClick={() => {
						setFirst(!first);
					}}
					className={`top-0 h-full cursor-pointer shadow-lg z-20 aspect-square rounded-full bg-white flex flex-col justify-center items-center transition-all duration-500 border-gray-400 text-center absolute ${
						first ? "right-0 " : "right-full translate-x-full "
					}`}
				>
					<div
						className={`rounded-full absolute top-1/2 -translate-y-1/2 flex flex-col transition-colors duration-300 items-center justify-center aspect-square ${
							first ? "text-black" : "text-black"
						}`}
					>
						<p className="text-sm md:text-base">NATURA</p>
						<p className="text-sm md:text-base">E CULTURA</p>
						<svg
							className={`${
								first ? "-rotate-0 text-black" : "rotate-180"
							} transition-all duration-500 md:w-[40px] w-[30px] aspect-square`}
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
							fill={first ? "#000" : "#000"}
						>
							<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
							<g
								id="SVGRepo_tracerCarrier"
								stroke-linecap="round"
								stroke-linejoin="round"
							></g>
							<g id="SVGRepo_iconCarrier">
								{" "}
								<title></title>{" "}
								<g id="Complete">
									{" "}
									<g id="arrow-left">
										{" "}
										<g>
											{" "}
											<polyline
												data-name="Right"
												fill="none"
												id="Right-2"
												points="7.6 7 2.5 12 7.6 17"
												stroke={first ? "#000" : "#000"}
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
											></polyline>{" "}
											<line
												fill="none"
												stroke={first ? "#000" : "#000"}
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												x1="21.5"
												x2="4.8"
												y1="12"
												y2="12"
											></line>{" "}
										</g>{" "}
									</g>{" "}
								</g>{" "}
							</g>
						</svg>
					</div>
				</div>
				<div
					className={`absolute grid md:grid-cols-2 place-items-center md:place-items-start grid-cols-3 z-10 md:gap-x-7 gap-x-20 rounded-full transition-opacity duration-500 leftd-6 translate-y-[-50%] top-[50%] ml-6 px-5 h-auto md:w-[370px] w-[125px] ${
						first ? " block" : "hidden"
					}`}
				>
					<div className="md:px-2 pl-6">
						<NormalBtn
							title="La Gorgia"
							sfondo="blue"
							flag="false"
							href="/gorgia"
						/>
					</div>
					<div className="md:px-2 pl-6">
						<NormalBtn
							title="Panchina gigante"
							sfondo="blue"
							flag="false"
							href="/panchina"
						/>
					</div>{" "}
					<div className="md:px-2 pl-6">
						<NormalBtn
							title="Fonte del Pianard"
							sfondo="blue"
							flag="false"
							href="/luoghi/pianard"
						/>
					</div>
					<div className="md:px-2 pl-6">
						<NormalBtn
							title="Meridiane e affreschi"
							sfondo="blue"
							flag="false"
							href="/meridianeeaffreschi"
						/>
					</div>
					<div className="md:px-2 pl-6">
						<NormalBtn
							title="L'Uja di Mondrone"
							sfondo="blue"
							flag="false"
							href="/uja"
						/>
					</div>
					<div className="md:px-2 pl-6">
						<NormalBtn
							title="Gite ed Escursioni"
							sfondo="blue"
							flag="false"
							href="/gite"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Megatoggle;
